@charset "utf-8";

/*
Theme Name: VITTO - Political React Template.
Author: Metropolitantheme
Author URL: https://themeforest.net/user/metropolitantheme
Version: 1.0.0

%%%%%%% Table of CSS %%%%%%%

1.General code
    1.1 Typography
    1.2 Space margins and padding
    1.3 forms
    1.4 Buttons
    1.5 Section-Colors-&-Backgrounds
    1.6 Modal & popups
2.Homepages
    2.1 Navigation
    2.2 Categories
    2.3 Recent Order
    2.4 Explore Collection
    2.5 Page Banner
    2.6 Advertisement Slider
    2.7 Blog Section
    2.8 Footer
        2.8.1 Footer Top
    2.10 Copyright
3.Blog Grid
4.Ex Deals
7.List View
8.Login & register
9.Checkout
10.Final Order
12.About Us
*/

/*======================
1.General Code
========================*/

html {
  overflow-x: hidden;
}
button:focus,
*:focus {
  outline: none;
}
body {
  font-family: "Open Sans", sans-serif;
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  line-height: 1.8;
  font-weight: 400;
  color: #000000;
  background: #ffffff;
  transition: transform ease-in 0.4s;
  overflow: hidden;
}
button {
  background: transparent;
  border: none;
  padding: 0;
}
label {
  line-height: normal;
}
.align-item-center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.p-relative {
  position: relative;
}
.after-none:after,
.none {
  display: none;
}
.custom-container {
  padding-right: 8%;
  padding-left: 8%;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.overlay-3 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
}
.overlay-bg {
  background: #141433;
  opacity: 0.8;
}
.overlay-bg-dark {
  background: linear-gradient(
    to right,
    rgb(191, 13, 62) 0%,
    rgba(191, 13, 62, 0.56) 30%,
    rgb(61, 72, 109) 100%,
    rgba(61, 72, 109, 0.25) 100%
  );
  z-index: 2;
}
.transform-center.z-index-3 {
  z-index: 3;
}

.overlay-bg-white {
  background: #ffffff;
  opacity: 0.5;
}
.overlay-2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.transform-center {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translate(0%, -50%);
  z-index: 1;
}
.image-fit {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
[class^="flaticon-"]:before,
[class*=" flaticon-"]:before {
  font-size: inherit;
  margin: 0;
}
.swiper-button-next,
.swiper-button-prev {
  box-shadow: 0 2px 14px 0px rgba(0, 0, 0, 0.1);
  width: 30px;
  height: 40px;
  background: #ba1c24;
  border-radius: 10%;
  top: 52%;
  transform: translate(0%, -48%);
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 14px;
  font-weight: 900;
  color: #fff;
}
.swiper-button-next {
  right: 10px;
}
.swiper-button-prev {
  left: 10px;
}
.swiper-button-disabled {
  display: none;
}
.back-btn button {
  box-shadow: 0 2px 14px 0px rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  background: #fff;
  font-size: 16px;
  font-weight: 900;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.u-line {
  border-bottom: 1px solid rgba(67, 41, 163, 0.2);
}
.banner-adv {
  position: relative;
  height: 70px;
  background: #4e0307;
  width: 100%;
  overflow: hidden;
}
.banner-adv .text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 15px;
  right: 15px;
  transform: translateY(-50%);
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  text-decoration: underline;
}
.banner-adv2 .close-banner,
.banner-adv .close-banner {
  cursor: pointer;
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  display: block;
  z-index: 9;
  color: #fff;
}
.banner-adv2 .close-banner:before,
.banner-adv2 .close-banner:after,
.banner-adv .close-banner:before,
.banner-adv .close-banner:after {
  border-bottom: 2px solid;
  content: "";
  position: absolute;
  left: 12px;
  top: 19px;
  width: 15px;
}
.banner-adv2 .close-banner:before,
.banner-adv .close-banner:before {
  transform: rotate(-45deg);
}
.banner-adv2 .close-banner:after,
.banner-adv .close-banner:after {
  transform: rotate(45deg);
}
.banner-adv2 .close-banner {
  display: none;
}
.banner-adv2 {
  position: relative;
  background: #fff;
  width: 100%;
  overflow: hidden;
}
.banner-adv2 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.banner-adv2 .text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 15px;
  right: 15px;
  transform: translateY(-50%);
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.banner-adv2 .text img {
  margin: 0 25px;
}
.z-index {
  z-index: 2;
}
#scrollstyle-4::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
#scrollstyle-4::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
#scrollstyle-4::-webkit-scrollbar-thumb {
  background-color: #141433;
}
.main-box {
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
  border-radius: 3px;
  background: #fff;
}
.section-header {
  margin: 0 auto;
  max-width: 660px;
  padding-bottom: 30px;
  text-align: center;
}
.section-header-left {
  margin: 0;
  text-align: left;
  max-width: 100%;
  padding-bottom: 30px;
}
.section-header-right {
  margin: 0 0 0 auto;
  text-align: right;
  max-width: 100%;
  padding-bottom: 30px;
}
.section-header-style-2 .header-title,
.section-header-left .header-title,
.section-header-right .header-title,
.section-header .header-title {
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 0;
}
.section-header-style-2 {
  margin: 0 auto;
  max-width: 660px;
  padding-bottom: 40px;
  text-align: center;
}
.section-header-style-2 .sub-title {
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 10px;
}
.full-height {
  height: 100%;
}
/*======================
1.1 Typography
========================*/

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 20px;
  font-family: "Rubik", serif;
  font-weight: 500;
  color: #000000de;
}
h1 {
  font-size: 60px;
  line-height: normal;
}
h2 {
  font-size: 48px;
}
h3 {
  font-size: 36px;
}
h4 {
  font-size: 30px;
}
h5 {
  font-size: 24px;
}
h6 {
  font-size: 22px;
}
p {
  font-size: 14px;
  line-height: 1.8;
  margin-bottom: 10px;
  font-family: "Open Sans", sans-serif;
}
a {
  color: #ba1c24;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
a:focus,
a:hover {
  color: #141433;
  text-decoration: none;
  outline: 0;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}
ul li,
ol li {
  margin: 0px;
  padding: 0px;
  position: relative;
  list-style: none;
}
blockquote {
  background-color: #f4f4f4;
  position: relative;
  padding: 3rem;
  margin: 2rem 0;
  border: #4329a333 solid 1px;
}
blockquote p {
  font-size: 32px;
  line-height: 45px;
  font-style: italic;
  margin: 0;
  word-break: break-word;
}
blockquote h6 {
  color: #000;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 16px;
}
img {
  max-width: 100%;
  width: auto;
  height: auto;
  border-radius: 3px;
  transition: 0.5s;
}
iframe {
  border: none;
  width: 100%;
}
table th,
table td {
  border: 1px solid #ccc;
  padding: 15px;
}
table {
  border: 1px solid #ccc;
  padding: 15px;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-22 {
  font-size: 22px;
}
.fs-40 {
  font-size: 40px;
}
.fs-50 {
  font-size: 50px;
}
.fs-60 {
  font-size: 60px;
}
.fw-100 {
  font-weight: 100;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 900;
}
.fw-700-bold {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.title-2 {
  display: flex;
  align-items: baseline;
}
.title-2 small {
  margin-left: 30px;
}
/*pagination*/

.custom-pagination {
  padding-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-pagination .page-link {
  padding: 10px 20px;
  color: #141433;
}
.custom-pagination .page-item .page-link:hover,
.custom-pagination .page-item.active .page-link {
  background: #141433;
  color: #fff;
  border-color: #141433;
}
/*======================
1.2 Space margins and padding
========================*/

.no-margin {
  margin: 0;
}
.no-padding {
  padding: 0;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.section-padding {
  padding: 80px 0;
}
.section-padding-top {
  padding-top: 80px;
}
.section-padding-bottom {
  padding-bottom: 80px;
}
.padding-10 {
  padding: 10px;
}
.padding-tb-10 {
  padding: 10px 0;
}
.padding-15 {
  padding: 15px;
}
.padding-20 {
  padding: 20px;
}
.main-sec {
  height: 60px;
  clear: both;
  width: 100%;
}
.mb-xl-20 {
  margin-bottom: 20px;
}
.mb-xl-40 {
  margin-bottom: 40px;
}
.pb-xl-20 {
  padding-bottom: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-23 {
  margin-bottom: 23px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-60 {
  margin-top: 60px;
}
.center-block {
  margin: 0 auto;
}
.mtmb-spacing {
  margin-top: 20px;
  margin-bottom: 70px;
}
/*==================
1.3. Forms
====================*/

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #495057bd;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
}
input[type="text"]::placeholder {
  color: #000000de;
  font-size: 14px;
}
.form-control-submit {
  border: 1px solid #8f8fa1;
  border-radius: 4px;
  padding: 10px 16px;
  height: 100%;
}
label.custom-checkbox input,
label.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
label.custom-checkbox,
label.custom-radio {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 20px;
  cursor: pointer;
  user-select: none;
  line-height: normal;
  font-size: 14px;
}
label.custom-checkbox:last-child,
label.custom-radio:last-child {
  margin-bottom: 10px;
}
/* Create a custom checkbox */

label.custom-checkbox .checkmark,
label.custom-radio .radio {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #d4d4db;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 4px;
}
/* On mouse-over, add a grey background color */

label.custom-checkbox:hover input ~ .checkmark,
label.custom-radio input:checked ~ .radio {
  background-color: #fff;
  border: 1px solid #ba1c24;
}
/* When the checkbox is checked, add a blue background */

label.custom-checkbox input:checked ~ .checkmark,
label.custom-radio input:checked ~ .radio {
  background-color: #ba1c24;
  border: 1px solid #ba1c24;
}
/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after,
.radio:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */

label.custom-checkbox input:checked ~ .checkmark:after,
label.custom-radio input:checked ~ .radio:after {
  display: block;
}
/* Style the checkmark/indicator */

label.custom-checkbox .checkmark:after {
  left: 6px;
  top: 4px;
  width: 5px;
  height: 9px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
label.custom-radio .radio:after {
  left: 4px;
  top: 4px;
  width: 10px;
  height: 10px;
  border: 5px solid #fff;
  transform: rotate(90deg);
  display: block;
  border-radius: 4px;
}
/*==================
1.4. Buttons
====================*/
.btn-text-white {
  color: #141433;
}
.btn-text:hover {
  color: #141433;
}
.btn-text-white::before,
.btn-text:hover:before {
  background-color: #141433;
}
.btn-text {
  font-size: 14px;
  position: relative;
  pointer-events: visible;
  text-transform: capitalize;
  color: #fff;
}
.btn-text:before {
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  content: "";
  position: absolute;
  background-color: #fff;
}
.btn.btn-text-white:hover {
  color: #ffffff;
}
.btn.btn-text-white:hover:before {
  background-color: #ffffff;
}

.btn-solid {
  font-family: "Open Sans", sans-serif;
  padding: 0.4375rem 1.875rem 0.4375rem;
  display: inline-block;
  font-size: 0.875rem;
  color: #fff;
  letter-spacing: 0.5px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.75rem;
  position: relative;
  white-space: nowrap;
  z-index: 1;
  background: #ba1c24;
  transition: all 0.5s ease;
  border-radius: 4px;
}
.btn-solid:hover {
  background-color: #141433;
}
a.btn-solid:hover {
  color: #ffffff;
}
.btn-big {
  padding: 0.6875rem 1.875rem 0.625rem;
  font-size: 1rem;
}
[class*="btn"].with-line {
  padding-right: 30px;
}
[class*="btn"].with-line span,
.read-more {
  position: relative;
  padding-right: 25px;
  transition: all 0.5s ease;
}

[class*="btn"] span i,
.read-more:after {
  position: absolute;
  right: 0;
  top: 2px;
  transition: all 0.5s ease;
}
[class*="btn"].with-line span {
  display: flex;
  align-items: center;
}
[class*="btn"].with-line span i {
  top: 50%;
  transform: translateY(-50%);
}
.btn-big i {
  font-size: 0.9375rem;
}
[class*="btn"] i {
  font-size: 0.8125rem;
  display: inline-block;
  vertical-align: middle;
}
[class*="btn"].with-line:hover span,
.read-more:hover {
  padding-right: 0;
  padding-left: 25px;
}
[class*="btn"].with-line:hover span i,
.read-more:hover:after {
  right: calc(100% - 15px);
}
/* Exta buttons */

.btn-first {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 6px 16px 5px;
  font-size: 13px;
  line-height: 1.595;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.white-btn {
  border-color: rgba(67, 41, 163, 0.2);
  background: #fff;
}
.white-btn:hover,
.white-btn:focus {
  border-color: #141433;
}
.green-btn {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.green-btn:hover,
.green-btn:focus {
  border-color: #141433;
  background: #fff;
  color: #141433;
}
.paypal-btn {
  color: #fff;
  background-color: #ffc439;
  border-color: #ffc439;
}
.paypal-btn:hover,
.paypal-btn:focus {
  border-color: #ffc439;
  background: #ffc439;
  color: #fff;
}
.category-btn {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: #ffffff94;
  border: 1px solid transparent;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 1.595;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #000;
  font-weight: 600;
}
.category-btn:hover,
.category-btn:focus {
  border-color: #141433;
  background: #141433;
  color: #fff;
}
.btn-second {
  display: inline-block;
  height: 50px;
  min-width: 150px;
  background: #141433;
  color: #fff;
  text-align: center;
  line-height: 50px;
  font-weight: 600;
  border-radius: 4px;
  z-index: 1;
  position: relative;
  overflow: hidden;
  padding: 0 40px;
  vertical-align: middle;
  font-size: 16px;
}

.btn-second img,
.btn-second-2 img {
  float: left;
  left: -8px;
  position: relative;
  width: 22px;
  height: 22px;
}
.btn-submit {
  color: #fff;
  background: #141433;
  border-color: #141433;
}
.btn-submit:hover,
.btn-submit:focus {
  border-color: #ba1c24;
  background-color: #ba1c24;
  color: #fff;
}
.btn-facebook {
  color: #fff;
  background: #3b5998;
  border-color: #3b5998;
}
.btn-facebook:hover,
.btn-facebook:focus {
  border-color: #2d4373;
  background: #2d4373;
  color: #fff;
}
.btn-google {
  color: #fff;
  background: #4285f4;
  border-color: #4285f4;
}
.btn-google:focus,
.btn-google:hover {
  border-color: #1266f1;
  background: #1266f1;
  color: #fff;
}
span.circle-tag {
  height: 36px;
  width: 36px;
  background: #fff;
  border-radius: 50%;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
span.square-tag > img,
span.rectangle-tag > img,
span.circle-tag > img {
  padding: 8px;
}
span.rectangle-tag {
  height: 30px;
  width: auto;
  border-radius: 3px;
  font-size: 12px;
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  letter-spacing: 2px;
  padding: 0 8px;
}
span.rectangle-tag + span.rectangle-tag {
  margin: 0 2px;
}
span.square-tag {
  height: 35px;
  width: 40px;
  background: transparent;
  border-radius: 3px;
  font-size: 16px;
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
span.share-tag {
  height: 36px;
  width: 36px;
  background: #fff;
  border-radius: 50%;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
span.deal-tag {
  border-radius: 3px;
  padding: 4px 10px;
  background-color: #fff;
  box-shadow: 0 1px 5px 0 rgba(66, 41, 163, 0.08),
    0 0 0 1px rgba(66, 41, 163, 0.08);
}
span.add-product {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: 1px solid #141433;
  background-color: #fff;
  color: #141433;
  box-shadow: 0 1px 5px 0 rgba(66, 41, 163, 0.08),
    0 0 0 1px rgba(66, 41, 163, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
span.add-product i {
  font-size: 12px;
}
span.type-tag {
  padding: 3px 15px;
  position: absolute;
  bottom: 0;
  border-radius: 5px 5px 0px 0px;
  cursor: pointer;
}
.btn-text-dark {
  color: #ba1c24;
}
.btn-text-dark:before {
  background-color: #ba1c24;
}
/*==================
1.5. Section-Colors-&-Backgrounds
====================*/

.bg-light-white {
  background-color: #f4f4f4;
}
.bg-light-theme {
  background-color: #fbfbfc;
}
.bg-theme-primary {
  background-color: #f8f7f5;
}
.bg-light-green {
  background-color: #28a745;
}
.bg-red {
  background-color: #ff0000;
}
.bg-gradient-red {
  background-image: linear-gradient(to right, #f20000, #a20000);
}
.bg-yellow {
  background-color: #ffc000;
}
.bg-green {
  background-color: #4aa110;
}
.bg-gradient-green {
  background-image: linear-gradient(to bottom, #499a14, #476d2d);
}
.bg-gradient-orange {
  background-image: linear-gradient(to bottom, #c55a11, #ed7d31);
}
.bg-black {
  background-color: #000;
}
.bg-custom-1 {
  background-color: #eb010d;
}
.bg-custom-2 {
  background-color: #046e04;
}
.bg-custom-3 {
  background-color: #eb010d;
}
.bg-custom-4 {
  background-color: #046e04;
}
/*Colors*/

.text-light-black {
  color: #000000de;
}
.text-orange {
  color: #fb6100;
}
.text-custom-white {
  color: #ffffff;
}
.text-light-white {
  color: #6b6b83;
}
.text-dark-red {
  color: #141433;
}
.text-dark-white {
  color: #00000059;
}
.text-yellow {
  color: #fcb71b;
}
.text-light-green {
  color: #141433;
}
.text-success {
  color: #13aa37;
}
/*======================
1.6 Modal & Popups
========================*/

#offer .modal-dialog {
  max-width: 500px;
  margin: 1.75rem auto;
}
.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
#offer.modal {
  height: auto;
  top: 50%;
  left: 0%;
  right: 0;
  transform: translate(0%, -50%);
}
.offer-content {
  text-align: center;
  background-repeat: no-repeat;
  width: 100%;
  padding: 20px 10px 10px;
  position: relative;
  min-height: 100%;
  background: linear-gradient(0deg, #6a1d89a8, #6a1d89a8);
  background-size: cover;
}
#offer .modal-header .close {
  position: absolute;
  right: 0;
  padding: 20px;
  margin: 0;
}
.offer-content h2 {
  text-transform: uppercase;
  font-size: 50px;
}
.offer-content h2 small {
  display: block;
  font-size: 30px;
  text-transform: lowercase;
}
#address-box .modal-content,
#search-box .modal-content {
  min-height: 85vh;
  background: #fbfbfc;
}
#address-box .modal-header,
#search-box .modal-header {
  display: block;
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
}
#address-box .modal-header .close,
#search-box .modal-header .close {
  margin: 0;
  display: block;
  width: 100%;
  text-align: left;
  color: #141433;
  opacity: 1;
  padding: 0;
  font-size: 35px;
  font-weight: 200;
  line-height: 1;
  margin-bottom: 20px;
}
#address-box .modal-title,
#search-box .modal-title {
  font-size: 20px;
}
#address-box .modal-body,
#search-box .modal-body {
  padding: 1rem 0;
}
/*======================
2. Homepages
========================*/

/* Top-Navigation */

.top-header {
  background: #eb010d;
}
.top-left {
  float: left;
}
.top-left .top-info li {
  float: left;
  list-style: none;
  color: #231b0a;
  font-size: 14px;
  font-weight: 500;
  margin-right: 15px;
  padding: 10px 0;
}
.top-left .top-info li:last-child {
  margin-right: 0;
}
.top-right {
  float: right;
}
.top-right ul {
  float: left;
}
.top-right ul li {
  padding: 10px 5px;
}
.top-left ul li:hover a,
.top-right ul li:hover a {
  color: #ffffff;
}
.top-right ul li i {
  font-size: 18px;
  vertical-align: middle;
}
.top-right ul li:last-child {
  padding: 10px 0 10px 5px;
}
.top-right ul li a,
.top-info li a {
  color: #fff;
}
.top-right ul li a i,
.top-info li a i {
  margin-right: 5px;
}
.top-right .top-btn {
  margin-left: 15px;
}
.top-right .top-btn {
  float: right;
  color: #ffffff;
  padding: 10px 19px 12px 19px;
  background-color: rgb(1, 107, 19);
}
.right-side-content {
  max-width: 650px;
  margin: 0 auto;
}
/*Navigation*/

@keyframes fadeHeaderInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.header.sticky {
  animation-name: fadeHeaderInDown;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 22;
  animation-duration: 1s;
  animation-fill-mode: both;
}
.header {
  position: fixed;
  left: 0;
  z-index: 10;
  display: flex;
  align-self: center;
  align-items: center;
  width: 100%;
  height: 60px;
  transform: translateZ(0);
  transition: all 0.2s ease;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.01), 0 1px 8px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
}
.header.not-fixed {
  position: relative;
}
.header .navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header .navigation .logo {
  width: 120px;
  height: 40px;
  margin: 10px 0;
  z-index: 10;
}
.main-navigation > nav > .main-menu {
  display: flex;
  align-items: center;
}
.main-navigation > nav > .main-menu > .menu-item {
  margin: 0 10px;
  padding: 15px 0;
}
.main-navigation > nav > .main-menu > .menu-item > a {
  font-weight: 500;
  font-size: 14px;
  padding: 0px 15px 0 10px;
  text-transform: uppercase;
  position: relative;
}
.main-navigation > nav > .main-menu > .menu-item > a:after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 2.5em;
  bottom: 0;
  width: 20%;
  height: 2px;
  background-color: #141433;
  content: "";
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
}
.main-navigation > nav > .main-menu > .menu-item:hover > a,
.main-navigation > nav > .main-menu > .menu-item.active > a {
  color: #141433;
}
.main-navigation > nav > .main-menu > .menu-item.active > a:after,
.main-navigation > nav > .main-menu > .menu-item:hover > a:after {
  opacity: 1;
  width: calc(100%);
}
.menu-item-has-children > a:before {
  display: inline-block;
  margin-left: 0.55em;
  content: "\e688";
  font-family: "Pe-icon-7-stroke";
  font-weight: 900;
  position: absolute;
  right: -5px;
}
.menu-item-has-children > .sub-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  transform: translateY(-10px);
  border-bottom: solid 2px #141433;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.15);
  background: #fff;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  width: 250px;
}
.menu-item-has-children:hover > .sub-menu {
  border-top: 1px solid rgba(67, 41, 163, 0.2);
  opacity: 1;
  visibility: visible;
  z-index: 999;
  transform: translateY(0px);
  transition: 0.3s;
}
.menu-item-has-children.nav-postion:hover > .sub-menu {
  top: 0;
  transform: translateY(-100%);
}
.menu-item-has-children > .sub-menu > li.menu-item {
  padding: 0 10px;
}
.menu-item-has-children.nav-postion
  > .sub-menu
  > li.menu-item-has-children
  > .sub-menu {
  top: inherit;
}
.menu-item-has-children > .sub-menu > li.menu-item > a {
  display: block;
  padding: 10px;
  font-size: 15px;
  font-weight: 500;
  border-bottom: 1px solid rgba(67, 41, 163, 0.2);
  transition: 0.5s;
  white-space: nowrap;
  color: #000000de;
}
.menu-item-has-children > .sub-menu > li.menu-item:last-child > a {
  border: none;
}
.menu-item-has-children > .sub-menu > li.menu-item:hover > a {
  padding-left: 25px;
  transition: 0.5s;
  color: #141433;
}
.menu-item-has-children > .sub-menu > li.menu-item-has-children > a:before {
  display: inline-block;
  margin-left: 0;
  content: "\e688";
  font-family: "Pe-icon-7-stroke";
  font-weight: 900;
  right: 10px;
  transform: rotate(-90deg);
}
.menu-item-has-children > .sub-menu > li.menu-item-has-children > .sub-menu {
  left: 100%;
  top: -3%;
}
.header .navigation .right-side-navigation ul {
  display: flex;
  align-items: center;
}
.header .navigation .right-side-navigation ul li {
  margin-left: 20px;
}
.header .navigation .right-side-navigation ul li a {
  font-size: 26px;
}
.header .navigation .right-side-navigation ul li.user-details > a {
  display: flex;
  align-items: center;
  color: #233359;
}
.header .navigation .right-side-navigation ul li.user-details span {
  font-size: 12px;
  margin-left: 10px;
}
.header .navigation .right-side-navigation ul li .cart {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #ba1c24;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  top: 8px;
  position: absolute;
  right: -8px;
}
.header .navigation .right-side-navigation .hamburger-menu {
  display: none;
  align-items: center;
  cursor: pointer;
}
.header .navigation .right-side-navigation .hamburger-menu .menu-btn {
  transition: 0.3s;
}
.header .navigation .right-side-navigation .hamburger-menu .menu-btn span {
  background: #141433;
  height: 2px;
  width: 25px;
  margin-bottom: 5px;
  display: block;
  transition: 0.3s;
}
.header
  .navigation
  .right-side-navigation
  .hamburger-menu
  .menu-btn
  span:last-child {
  margin-bottom: 0;
}
.header .navigation .right-side-navigation .hamburger-menu .menu-btn.active {
  position: relative;
  top: -4px;
  transition: 0.3s;
}
.header
  .navigation
  .right-side-navigation
  .hamburger-menu
  .menu-btn.active
  span:first-child {
  transform: rotate(45deg);
  transition: 0.3s;
  position: relative;
  top: 7.4px;
  background-color: #141433;
}
.header
  .navigation
  .right-side-navigation
  .hamburger-menu
  .menu-btn.active
  span:nth-child(2) {
  display: none;
}
.header
  .navigation
  .right-side-navigation
  .hamburger-menu
  .menu-btn.active
  span:last-child {
  transform: rotate(-45deg);
  transition: 0.3s;
  background-color: #141433;
}
.video-banner {
  position: relative;
  background-color: black;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.video-banner video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.section-block {
  max-width: 420px;
  margin: 0 auto;
}
/* banner-3 */

.home-3-banner .banner-left {
  position: relative;
}
.home-3-banner .banner-left .video-icon {
  position: absolute;
  top: 50%;
  right: -25px;
  z-index: 3;
}
.home-3-banner .banner-left .video-icon i {
  color: #fff;
  background: #000000;
  border-radius: 50%;
  padding: 20px;
  width: 50px;
  height: 50px;
  line-height: 12px;
  text-align: center;
  -webkit-animation: animate2 3s linear infinite;
  animation: animate2 3s linear infinite;
}
/*Categories*/

.browse-cat .categories {
  text-align: center;
  display: block;
}
.browse-cat .categories span {
  transition: all 0.2s ease-in-out;
}
.browse-cat .categories:hover span {
  color: #014713;
}
.browse-cat .categories div > img {
  transition: all 0.2s ease-in-out;
}
.browse-cat .categories:hover div > img {
  transform: scale(0.9);
}
.browse-cat .categories .icon {
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 125px;
  height: 125px;
}
.browse-cat .categories .icon2 {
  width: 100%;
  height: 100%;
}
.browse-cat .categories .icon i {
  font-size: 45px;
}
.browse-cat .categories .icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}
.browse-cat .categories .cat-name {
  padding: 5px 0;
  display: block;
  font-size: 14px;
  font-weight: 600;
}
.browse-cat-2 .categories .icon {
  position: relative;
}
.browse-cat-2 .categories .icon img {
  border-radius: 0;
}
.browse-cat-2 .categories .icon .product-addbtn {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.browse-cat-2 .categories .icon .product-addbtn span i {
  font-size: 12px;
}
/* Categories-2 */

.category-block {
  position: relative;
}
.list-adv2 .category-block {
  margin: 0 -15px 0px;
}
.adv-thumb-link {
  display: block;
  overflow: hidden;
  position: relative;
}

.fade-out-in .adv-thumb-link::before,
.fade-out-in .adv-thumb-link::after,
.fade-in-out .adv-thumb-link::before,
.fade-in-out .adv-thumb-link::after,
.overlay-image .adv-thumb-link::after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  background: linear-gradient(
    to right,
    rgba(186, 28, 36, 0.65) 0%,
    rgba(186, 28, 36, 0.56) 30%,
    rgba(20, 20, 51, 0.65) 100%,
    rgba(61, 72, 109, 0.25) 100%
  );
  opacity: 0.5;
  z-index: 1;
  transition: all 0.5s ease-out 0s;
}
.adv-thumb-link > img {
  display: block;
  width: 100%;
  transition: all 0.5s ease-out 0s;
}
.item-adv2 .banner-info {
  top: auto;
  bottom: 60px;
  padding: 0 15px;
}
.banner-info {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
}
.font-italic {
  font-style: italic;
}
.title30 {
  font-size: 30px;
}
.item-adv2 .banner-info p {
  max-width: 270px;
  margin: 14px auto 22px;
}
.opaci {
  opacity: 0.8;
}
.fade-out-in:hover .adv-thumb-link::before {
  opacity: 0.5;
  background: #141433;
}
.fade-out-in:hover .adv-thumb-link::after {
  opacity: 0.5;
  background: #141433;
}
.white-block a.btn-text:hover {
  color: #000000;
}
.white-block a.btn-text:hover::before {
  background-color: #000000;
}
/*Recent Order*/

.product-box {
  position: relative;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
  margin-bottom: 30px;
}
.product-box-3 {
  position: relative;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
}
.swiper-slide .testimonial-wrapper,
.swiper-slide .product-box-3,
.swiper-slide .product-box {
  margin: 4px;
}
.product-box-3:hover,
.product-box:hover {
  box-shadow: 0 0 0 1px rgba(186, 28, 36, 0.2),
    0 1px 5px 0 rgba(186, 28, 36, 0.55);
}
.product-box-3 .product-img,
.product-box .product-img {
  position: relative;
  overflow: hidden;
}
.product-box-3 .product-caption,
.product-box .product-caption {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 15px;
  width: 100%;
  padding-top: 5px;
}
.product-box .product-img a img {
  object-fit: scale-down;
  transform: scale(0.9);
  transition-duration: 0.4s;
}
.product-box:hover .product-img a img {
  transform: scale(1);
}
.product-box .product-caption .title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.product-box .product-caption .product-title {
  margin-bottom: 0px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.product-box .product-caption p {
  font-size: 12px;
}
.product-box .product-caption .product-btn {
  margin-top: 10px;
}
.product-box .product-img .product-tags .custom-tag,
.product-box .product-img .product-tags {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.product-box .product-img .product-tags > span:first-child {
  position: absolute;
  top: 10px;
  right: 10px;
}
.product-box .product-caption .product-details {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.product-box .product-caption .product-details span {
  display: block;
  line-height: normal;
}
.product-box .product-caption .product-footer {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.product-box .product-footer-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fbfbfc;
  padding: 12px 10px;
}
.product-box .product-rating i {
  color: #ffa900;
}
.product-box .product-img .product-action {
  position: absolute;
  left: 0px;
  right: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1;
}
.product-box .product-img .product-action i {
  font-size: 20px;
  border: 1px solid #fff;
  padding: 15px;
}
.product-box .product-img .product-action i:hover {
  border: 1px solid #fff;
  background-color: #fff;
  color: #000;
}
.product-box .product-img .product-action i {
  color: red;
}
.product-box .product-img .product-action a {
  margin: 0px 4px 0px 4px;
  visibility: hidden;
  transition: 0.3s;
}
.product-box .product-img:hover .product-action a {
  visibility: visible;
  transition: 0.3s;
}
.product-box .product-img .product-badge {
  position: absolute;
  color: #fff;
  top: 0px;
  left: 0px;
  font-size: 15px;
  z-index: 1;
}
.product-box .product-img .product-badge .badge {
  background-color: red;
}
.product-box .product-money {
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-box .product-money span {
  color: #ba1c24;
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
}
.product-box .product-money span + span {
  text-decoration: line-through;
  color: gray;
  font-weight: normal;
  font-size: 13px;
  margin-left: 4px;
}
.product-box {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
/* Product badge */

.product-label {
  background: #28a745;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  left: 10px;
  line-height: 1;
  min-width: 46px;
  padding: 4px 12px;
  position: absolute;
  text-align: center;
  text-transform: capitalize;
  z-index: 3;
}
.product-label span {
  display: block;
}
.product-label:nth-child(2) {
  top: 40px;
}
.product-label:nth-child(1) {
  top: 10px;
}
.product-label.discount {
  background-color: #141433;
}
.product-label.nonveg {
  background-color: red;
}
.product-caption .product-category a {
  font-size: 14px;
  line-height: 1;
  color: #777777;
  text-transform: capitalize;
}
/* Cart-hover button */

.cart-hover {
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}
.product-box:hover .cart-hover {
  bottom: 0px;
  opacity: 1;
  visibility: visible;
}
.btn-cart {
  width: 100%;
  display: inline-block;
  border-radius: 0;
  border-color: transparent;
  text-transform: capitalize;
  background: #ba1c24;
  color: #fff;
  padding: 10px 15px;
}
.btn-cart:hover {
  color: #ffffff;
  background: #141433;
}
/* Button Group */

.button-group {
  right: 15px;
  top: 15px;
  position: absolute;
}
.product-box:hover .button-group a {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.button-group a {
  width: 38px;
  height: 38px;
  display: block;
  font-size: 18px;
  line-height: 42px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 10px;
  background-color: #141433;
  -webkit-transform: scale(0.6);
  -ms-transform: scale(0.6);
  transform: scale(0.6);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  opacity: 0;
  visibility: hidden;
}
/* Hot Details */

.banner-statistics {
  position: relative;
  overflow: hidden;
}
.banner-statistics img {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.banner-statistics:hover img {
  transform: scale(1.1);
}
.banner-statistics::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(76, 40, 60, 0.69);
  top: 0;
  left: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0.8;
  pointer-events: none;
  z-index: 1;
}
.banner-statistics.dontaion-promo::before {
  background: rgb(209, 162, 132);
}
.banner-statistics:hover .promo-lines::before,
.banner-statistics:hover .promo-lines::after {
  transform: scale(1);
  -webkit-transform: scale(1);
}
.banner-statistics .promo-lines:before {
  position: absolute;
  top: 20px;
  bottom: 20px;
  left: 10px;
  right: 10px;
  content: "";
  border-top: 1px solid rgb(76, 40, 60);
  border-bottom: 1px solid rgb(76, 40, 60);
  z-index: 1;
  transform: scaleX(0);
  -webkit-transform: scaleX(0);
  transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
}
.banner-statistics .promo-lines:after {
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 20px;
  right: 20px;
  content: "";
  border-left: 1px solid rgb(76, 40, 60);
  border-right: 1px solid rgb(76, 40, 60);
  z-index: 1;
  transform: scaleY(0);
  -webkit-transform: scaleY(0);
  transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
}
.banner-statistics:hover::before {
  opacity: 1;
  background: rgba(248, 247, 245, 0.28);
}
.banner-content {
  left: 55px;
  top: 50%;
  position: absolute;
  pointer-events: none;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2;
}
.banner-text1 {
  font-size: 15px;
  line-height: 1.1;
  color: #141433;
  text-transform: uppercase;
}
.banner-text2 {
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}
.banner-text2 span {
  display: block;
}
/*our-products*/

.our-product .product-box .product-clearfix .price-old {
  font-size: 12px;
  color: gray;
  text-decoration: line-through;
}
.our-product .product-box .product-clearfix .price-new {
  padding: 0 5px;
  color: #141433;
  font-size: 12px;
}
.our-product .product-box .product-links {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  font-size: 30px;
  transform: translateY(-50%);
  visibility: hidden;
}
.product-cart {
  align-items: center;
}
.our-product .product-box .product-button a {
  border: 1px solid #141433;
  font-size: 14px;
}
.our-product .product-box .product-button {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
  visibility: hidden;
  transition: 0.1s;
}
.our-product .product-box:hover .product-button {
  visibility: visible;
  transition: 0.1s;
}
.our-product .product-box .product-links a i {
  color: red;
}
.our-product .product-box:hover .product-links {
  visibility: visible;
  transition: 0.1s;
}
.our-product .product-box .product-badge-1 {
  position: absolute;
  color: #fff;
  top: 12px;
  right: 10px;
  font-size: 15px;
  z-index: 1;
  border: 3px;
}
.our-product .product-box .product-badge-1 span {
  background-color: #40e240;
}
/*Our products*/

/*featured-product*/

.featured-product {
  position: relative;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
  margin: 3px;
}
.featured-product:hover {
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.2),
    0 1px 5px 0 rgba(67, 41, 163, 0.2);
}
.featured-product .featured-product-details {
  padding: 15px;
  display: flex;
}
.featured-product .featured-product-details .pro-logo {
  width: 64px;
  height: 64px;
  overflow: hidden;
  flex-shrink: 0;
  background-color: #fff;
  box-shadow: 0 1px 5px 0 rgba(66, 41, 163, 0.08),
    0 0 0 1px rgba(66, 41, 163, 0.08);
  margin-right: 15px;
}
.featured-product .featured-product-details .pro-title {
  max-width: 200px;
}
/*product-box-3*/

.product-box-3 .product-caption ul {
  display: flex;
  flex-wrap: wrap;
}
.product-box-3 .product-caption ul.food-type li {
  padding: 0 7px;
  position: relative;
  margin-bottom: 10px;
}
.product-box-3 .product-caption ul.food-type li:after {
  content: "";
  background: #6b6b83;
  width: 3px;
  height: 3px;
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0px, -50%);
}
.product-box-3 .product-caption ul.food-type li:first-child {
  padding-left: 0;
}
.product-box-3 .product-caption ul.food-type li:last-child:after {
  display: none;
}
.product-box-3 .product-caption ul.food-description li {
  background-color: #f6f6f6;
  padding: 2px 8px;
  margin-right: 8px;
}
/*Explore Collection*/

.ex-collection-box {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.ex-collection-box:hover img {
  transform: scale(1.1);
  transition: 0.5s;
}
.ex-collection .category-type a {
  position: absolute;
  bottom: 0;
  margin-bottom: 15px;
  margin-left: 21px;
  z-index: 1;
}
.ex-collection .ex-content {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translate(0%, -50%);
  padding: 0 40px;
  max-width: 750px;
  margin: 0 auto;
  word-wrap: break-word;
  justify-content: space-between;
  color: #fff;
}
.ex-collection .ex-content h4:after {
  content: "";
  position: absolute;
  border-right: 1px solid #000;
}
.ex-collection .ex-content p {
  font-size: 20px;
  font-weight: 500;
}
.ex-collection .ex-content p span {
  font-weight: 500;
}
.ex-collection .line {
  position: absolute;
  width: 55px;
  height: 3px;
  background: #fff;
  top: 26px;
  left: 40px;
}
.large-product-box {
  position: absolute;
  top: 0;
  height: 100%;
  bottom: 0;
  overflow: hidden;
  right: 15px;
  left: 15px;
}
.large-product-box .category-type a {
  left: 15px;
  width: auto;
  right: 15px;
  margin-bottom: 35px;
}
/*Page Banner*/

.banner-1 {
  height: 700px;
  width: 100%;
}
.banner-1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.banner-1 .content-wrapper {
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
  border-radius: 3px;
}
.banner-1 .content-wrapper .content-box .input-group {
  margin: 0 -8px;
}
.banner-1 .content-wrapper .content-box .input-group-prepend {
  height: 48px;
  min-width: 44px;
  flex-basis: 44px;
  border: 1px solid #8f8fa1;
  border-left: 0;
  border-radius: 0px 4px 4px 0;
  display: none;
}
.banner-1 .content-wrapper .content-box .input-group-prepend .input-group-text {
  line-height: 1.5;
  font-size: 20px;
  background: transparent;
  border: none;
}
.banner-1 .content-wrapper .content-box .input-group .input-group2 {
  display: flex;
}
.banner-1 .content-wrapper .content-box .input-group .input-group2,
.banner-1 .content-wrapper .content-box .input-group-append {
  padding: 0 8px;
}
.banner-1 .right-side-content h1 {
  font-size: 60px;
  line-height: 1.2;
}
/*Advertisement Slider*/

.advertisement-slider {
  height: 100%;
}
.advertisement-slider .swiper-wrapper .swiper-slide .large-product-box {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
}
.advertisement-slider
  .swiper-wrapper
  .swiper-slide
  .large-product-box
  .content-wrapper {
  z-index: 1;
  position: absolute;
  bottom: 20px;
}
.advertisement-slider
  .swiper-wrapper
  .swiper-slide
  .large-product-box
  .tag-box {
  position: absolute;
  top: 20px;
  z-index: 1;
}
.advertisement-banner {
  /* background-image: url("../img/donate.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
}
.advertisement-banner-1:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background: linear-gradient(
    to right,
    rgb(20, 20, 51) 20%,
    rgba(20, 20, 51, 0.93) 50%,
    rgba(250, 250, 250, 0.1) 80%,
    rgba(250, 250, 250, 0) 100%
  );
}
.center-bg-effect:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  top: 0;
  background: linear-gradient(
    to right,
    rgb(186, 28, 36) 0%,
    rgba(186, 28, 36, 0.7) 30%,
    rgba(20, 20, 51, 0.7) 60%,
    rgb(20, 20, 51) 100%
  );
}
.advertisement-banner-2:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background: linear-gradient(
    to right,
    rgba(76, 40, 60, 0) 0%,
    rgba(76, 40, 60, 0) 20%,
    rgb(76, 40, 60) 50%,
    rgb(76, 40, 60) 20%
  );
}
.advertisement-banner .advertisement-text {
  text-align: center;
  color: #fff;
}
.advertisement-banner p {
  max-width: 650px;
  margin: 0 auto;
}
.advertisement-banner-2 {
  background-image: url("../img/inner-banner-3.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  position: relative;
}
.advertisement-banner-1 {
  background-image: url("../img/innerbanner2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  position: relative;
}
.advertisement-banner-2 .advertisement-text-1,
.advertisement-banner-1 .advertisement-text-1 {
  color: #fff;
  max-width: 550px;
}
.advertisement-banner-2 .advertisement-text-1 {
  text-align: right;
}
.advertisement-banner-2 .advertisement-text-1 .sub-head,
.advertisement-banner-1 .advertisement-text-1 .sub-head {
  font-size: 18px;
  color: #ffffff;
}
.advertisement-banner-1 .ad-count {
  display: flex;
  justify-content: flex-end;
}
.advertisement-banner-2 .ad-count {
  display: flex;
  justify-content: flex-end;
}
/*Blog Section*/

.our-blog .blog-box {
  display: flex;
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
  border-radius: 3px;
  background: #fff;
}
.our-blog .blog-box .post {
  flex: 0 0 66.6666%;
  max-width: 66.6666%;
}
.our-blog .blog-box .side-blog {
  flex: 0 0 33.3334%;
  max-width: 33.3334%;
}
.our-blog .blog-box .post .blog-img img,
.our-blog .blog-box .post .blog-img {
  height: 100%;
}
.our-blog .blog-box .post .blog-img .blog-meta {
  bottom: 20px;
  position: absolute;
  z-index: 1;
}
.our-blog .blog-box .side-blog .blog-list .side-post {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.our-blog .blog-box .side-blog .blog-list .side-post .thumb-img {
  position: relative;
  overflow: hidden;
  flex: 0 0 100px;
  max-width: 100px;
}
.our-blog .blog-box .side-blog .blog-list .side-post .content-wrap {
  flex: 0 0 calc(100% - 100px);
  max-width: calc(100% - 100px);
}
.side-post .entry-title {
  font-size: 16px;
  font-weight: 500;
}
.side-bar .pb-xl-20,
.side-post.pb-xl-20 {
  padding-bottom: 10px;
}
.side-bar .mb-xl-20,
.side-post.mb-xl-20 {
  margin-bottom: 10px;
}
.side-bar .main-box {
  margin-bottom: 30px;
}
.side-bar h5 {
  font-weight: 700;
  margin-left: 10px;
  padding-left: 10px;
  position: relative;
  font-size: 22px;
}
.side-bar h5:before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  color: #ba1c24;
}
/*shop Details*/

.detail-slider .gallery-top .swiper-slide .shop-type-tag {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #fff;
  background-color: #141433;
  border-radius: 0.25rem;
}
.pro-review {
  padding-left: 10px;
}
.pro-review span {
  font-size: 14px;
  line-height: 1;
  color: #555555;
}
.shop-detail-contents .price .price-tag {
  font-size: 25px;
  font-weight: 800;
  font-family: "Open Sans", sans-serif;
}
.shop-detail-contents .price h6 span {
  margin-left: 5px;
  text-decoration: line-through;
  vertical-align: middle;
  font-size: 18px;
  font-weight: 500;
}
.text-light-white {
  color: #000000;
}
.text-custom-red {
  color: #141433;
}
.detail-slider .gallery-thumbs {
  height: 20%;
  box-sizing: border-box;
  padding: 15px 0;
}
.detail-slider .gallery-thumbs .swiper-slide {
  cursor: pointer;
}
.type-tag {
  color: #fff;
  display: inline-block;
  padding: 3px 14px;
  font-size: 14px;
}
.type-tag:hover {
  color: #fff;
}
.shop-detail-content-wrapper {
  max-width: 435px;
}
.shop-detail-contents .quantity {
  width: 380px;
  display: flex;
  align-items: center;
}
.shop-detail-contents .quantity input {
  border-right: 0;
  border-left: 0;
}
.product-qty-input {
  width: 40%;
  display: flex;
  border: 1px solid #9494943b;
  border-radius: 4px;
}
.form-control-custom {
  height: 40px;
  padding: 0.375rem 1.2rem;
  border: 0px;
  width: 100%;
  background: none;
}
.shop-detail-contents .quantity button {
  height: 40px;
  border: 0px;
  padding: 0 20px;
  background: #fbfbfc;
  color: #202021;
}
.shop-detail-contents .form-control-qty {
  height: 40px;
  padding: 0.9rem 0.5rem;
  border-radius: 0;
  border: 0px;
  width: 50%;
  background: #f8f7f5;
}
.shop-detail-contents .shop-bottom .shop-meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.shop-detail-description-sec {
  padding-top: 80px;
}
.shop-detail-description-sec .nav-tabs {
  border: 0;
}
.shop-detail-description-sec .nav-tabs .nav-item {
  margin-right: 5px;
}
.shop-detail-description-sec .nav-tabs .nav-item:last-child {
  margin-right: 0px;
}
.shop-detail-description-sec .nav-tabs .nav-item a {
  color: #fff;
  display: block;
  font-size: 16px;
  line-height: 1;
  text-transform: capitalize;
  padding: 12px 16px;
  color: #555555;
  background-color: #fff;
  border-bottom: none;
  border-radius: 0;
}
.shop-detail-description-sec .nav-tabs .nav-item a:hover,
.shop-detail-description-sec .nav-tabs .nav-item a.active {
  color: #fff;
  background-color: #ba1c24;
}
.shop-detail-description-sec .tabs .tab-content {
  border: 1px solid #efefef;
  padding: 20px;
  background-color: #ffffff;
  border-top: 1px solid transparent;
  padding-bottom: 0;
}
.shop-detail-description-sec .comment-box .children,
.shop-detail-description-sec .comment-box .comments {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 20px;
  list-style: inherit;
}
.shop-detail-description-sec .comment-box .comments li.comment {
  list-style: outside none none;
  padding-left: 0;
  margin-bottom: 0px;
}
.shop-detail-description-sec .comment-box .comments li article .comment-avatar {
  width: 80px;
  margin-right: 20px;
  float: left;
  overflow: hidden;
  height: 80px;
  border-radius: 50px;
}
.shop-detail-description-sec
  .comment-box
  .comments
  li
  article
  .comment-content
  .comment-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  flex-wrap: wrap;
}
.shop-detail-description-sec .btn-first {
  border: 1px solid #ba1c24;
}
.shop-detail-description-sec .btn-first:hover {
  border: 1px solid #141433;
}
.shop-detail-description-sec .post-date {
  font-size: 14px;
  color: #464545;
  font-weight: 600;
}
.shop-detail-description-sec .comment-box .children {
  padding-left: 95px;
}
.shop-detail-description-sec .comment-box .comments li article {
  display: flex;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
}
/*footer*/

footer {
  border-top: 1px solid rgba(67, 41, 163, 0.2);
}
footer .ft-logo-box {
  display: flex;
  align-items: center;
}
footer .ft-logo-box .ft-logo {
  margin-right: 25px;
}
footer .ft-social-media {
  display: block;
  align-items: center;
  height: 100%;
}
footer .ft-social-media ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
footer .ft-social-media ul li {
  margin-left: 15px;
  border: 1px solid #ada3a3;
  transition: 0.3s;
}
footer .ft-social-media ul li a {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ada3a3;
  transition: 0.3s;
  text-decoration: none;
}
footer .ft-social-media ul li a i {
  font-size: 18px;
}
footer .ft-social-media ul li:first-child {
  margin-left: 0;
}
footer .subscribe_form button {
  position: absolute;
  right: 3px;
  top: 3px;
  z-index: 999;
}
.footer-links i {
  font-size: 18px;
}
.copyright .medewithlove {
  overflow: hidden;
}
.copyright .madewithlove a {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.copyright .medewithlove a:hover i {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.copyright .medewithlove a i {
  color: red;
}
.instagram-slider > ul,
.hm-list.hm-instagram {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}
.instagram-slider > ul li,
.hm-list.hm-instagram li {
  overflow: hidden;
  position: relative;
  margin-right: 0;
}

.hm-list li {
  display: inline-block;
  margin-right: 15px;
}
.instagram-slider > ul li img,
.hm-list.hm-instagram li img {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 0px;
}
.instagram-slider > ul li:hover img,
.hm-list.hm-instagram li:hover img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.hm-list .insta-icon {
  position: absolute;
  top: 60%;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(-50%);
  font-size: 40px;
  color: #141433;
  line-height: 0;
  text-align: center;
  opacity: 0;
}
.instagram-slider .swiper-slide:hover .insta-icon {
  opacity: 1;
}
.instagram-slider {
  position: relative;
  top: -1px;
}
footer .subscribe_form input {
  padding: 10px 55px 10px 10px;
}
footer .ft-social-media ul li:hover {
  border-color: #141433;
  transform: translateY(-5px);
  transition: 0.3s;
}
footer .ft-social-media ul li:hover a {
  color: #141433;
  transform: rotate(25deg);
  transition: 0.3s;
}
footer .ft-logo-box {
  padding-bottom: 80px;
}
footer .footer-links,
footer .footer-contact {
  padding-top: 80px;
}
footer .footer-links ul,
footer .footer-contact ul {
  margin-bottom: 10px;
}
footer .footer-contact p {
  max-width: 280px;
}
footer .footer-contact li {
  margin-bottom: 10px;
}
footer .footer-contact li a {
  text-decoration: underline;
}
footer .footer-links ul li {
  margin-bottom: 5px;
}
footer .footer-links ul li > a {
  position: relative;
  transition: 0.3s all;
}
footer .footer-links ul li:hover > a {
  padding-left: 10px;
}
footer .footer-links ul li > a:before {
  display: inline-block;
  content: "\e688";
  font-family: "Pe-icon-7-stroke";
  transform: rotate(-90deg);
  font-size: 20px;
  line-height: 1;
  position: relative;
  top: 4px;
  right: 4px;
}
footer .footer-links .contact-info li a:before {
  display: none;
}
footer .footer-links .appimg {
  margin-bottom: 10px;
}
footer .footer-links .contact-info li a {
  display: flex;
  align-items: center;
}
footer .footer-links .contact-info li a span {
  margin-right: 10px;
  font-size: 22px;
}
/*footer-top*/

.footer-top .icon-box {
  margin: 0 auto;
  text-align: center;
  max-width: 150px;
  word-break: break-all;
}
.footer-top .icon-box span i {
  font-size: 50px;
  margin-bottom: 10px;
}
.footer-top .icon-box span {
  display: block;
  font-size: 12px;
}
/*copyright*/

.copyright {
  padding: 20px 0;
  background: #e60000;
}
.copyright .payment-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.copyright .copyright-text {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}
/*======================
3. Blog Grid
========================*/

.breadcrumb .breadcrumb-item {
  margin: 0;
}
.breadcrumb {
  margin: -20px;
  background-color: #fff;
  padding: 1.5rem 20px;
  border-bottom: 1px solid #4329a333;
  border-radius: 0;
  margin-bottom: 20px;
}
.breadcrumb .breadcrumb-item a {
  color: #131313;
  font-weight: 600;
}
.breadcrumb-item.active {
  color: #141433;
}
.breadcrumb-area {
  background-image: url("../img/innerbanner.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 225px;
  position: relative;
}
.breadcrumb-area .breadcrumb-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.breadcrumb-area .breadcrumb-content > h2 {
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  font-size: 36px;
  margin-bottom: 0;
  padding-bottom: 20px;
}
.breadcrumb-area .breadcrumb-content ul > li:first-child {
  padding-left: 0;
}
.breadcrumb-area .breadcrumb-content ul > li {
  color: #000000;
  display: inline-block;
  padding-left: 20px;
  position: relative;
}
.breadcrumb-area .breadcrumb-content ul > li:before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  position: absolute;
  right: -15px;
  top: 50%;
  transform: translateY(-45%);
  font-size: 10px;
  color: #ffffff;
}
.breadcrumb-area .breadcrumb-content ul > li:last-child:before {
  display: none;
  content: none;
}
.breadcrumb-area .breadcrumb-content ul > li > a {
  color: #ffffff;
}
.breadcrumb-area .breadcrumb-content ul > li {
  color: #ffffff;
  display: inline-block;
  padding-left: 20px;
  position: relative;
}
.breadcrumb-area .breadcrumb-content ul > li.active {
  color: #ba1c24;
  font-weight: 600;
}
.our-articles .blog-page-banner:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent);
}
.our-articles .blog-inner {
  position: relative;
  margin-top: -450px;
  display: flex;
}
.side-shop .side-post,
.side-blog .side-post {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.side-shop .content-wrap,
.side-blog .content-wrap {
  flex: 0 0 calc(100% - 100px);
  max-width: calc(100% - 100px);
}
.side-shop .thumb-img {
  margin-right: 15px;
}
.side-shop .thumb-img,
.side-blog .thumb-img {
  position: relative;
  overflow: hidden;
  flex: 0 0 100px;
  max-width: 100px;
}
.side-shop .entry-tye {
  line-height: 1;
}
.side-shop .side-post:last-child,
.side-blog .side-post:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}
.side-bar .blog-tags a {
  display: inline-block;
  padding: 5px 10px;
  border: #ba1c24 solid 1px;
  margin: 0 5px 10px 0;
  border-radius: 4px;
  background: #fff;
  font-weight: 500;
  color: #ba1c24;
}
.side-bar .blog-tags a:hover {
  background: #ba1c24;
  color: #fff;
  border: #ba1c24 solid 1px;
}
.side-bar .trending-blog-cat li {
  display: flex;
  position: relative;
}
.side-bar .trending-blog-cat li a {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  text-transform: capitalize;
  justify-content: space-between;
}
.side-bar .large-product-box {
  position: relative;
  height: auto;
  padding-bottom: 0;
  right: 0;
  left: 0;
}
.side-bar .large-product-box .category-type a {
  bottom: 0;
  position: absolute;
  margin-bottom: 15px;
}
.side-bar .main-padding {
  padding: 15px;
}
.side-bar .inner-wrapper {
  z-index: 1;
}
.side-bar .main-banner,
.side-bar .section-2 {
  height: auto;
}
.side-bar .main-padding img {
  width: 110px;
}
.side-bar .main-banner .footer-img {
  max-width: 200px;
}
.side-bar .inner-wrapper .main-page .login-box {
  display: block;
}
.our-articles .blog-services-wrapper {
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 10px;
}
.our-articles .blog-services-wrapper .post-meta {
  display: flex;
  align-items: center;
  padding: 20px;
  padding-bottom: 0;
}
.our-articles .blog-services-wrapper .author-meta {
  margin-left: 20px;
}
.our-articles .post-img {
  position: relative;
}
.our-articles .post-img .blog-meta-2 {
  background-color: rgba(255, 255, 255, 0.83);
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 10px;
  text-align: center;
  border-radius: 4px;
}
.our-articles .post-img .blog-meta-2 .blog-time_schedule .day {
  width: 100%;
  display: block;
  font-size: 18px;
  font-weight: 700;
  color: #141433;
}
/*======================
4. Ex Deals
========================*/

.exclusive-deals .deals-heading {
  padding: 15px 0;
}
.exclusive-deals .deals-image {
  display: flex;
  align-self: center;
  align-items: center;
}
/*product-box-2*/

.product-box-2 {
  display: flex;
}
.product-box-2 .product-img {
  width: 120px;
  height: 112px;
}
.product-box-2 .product-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.local-deals .btn-first {
  margin: 20px auto 40px;
}
/*======================
5. Restaurent
========================*/

.page-banner {
  height: 270px;
}
.page-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.page-banner > .overlay-2 > .container > .row {
  padding-top: 10px;
}
.tag-share {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
/*Restaurent Details*/

.heading .title {
  margin-bottom: 5px;
}
.heading .sub-title span {
  margin-left: 20px;
}
.restaurent-logo {
  position: absolute;
  top: -40%;
  right: 15px;
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
  background: #fff;
  width: 80px;
  height: 80px;
}
.heading .head-rating .rating span.rate-data {
  margin-left: 5px;
  vertical-align: middle;
  position: relative;
  top: -1px;
}
.heading .head-rating,
.heading .head-rating .product-review {
  display: flex;
  align-items: center;
}
.heading .head-rating .product-review {
  margin-left: 35px;
}
.heading .head-rating .product-review h6 {
  margin-right: 25px;
}
.heading .head-rating .product-review h6 span {
  font-weight: 400;
}
.restaurent-details-mob {
  display: none;
  text-align: center;
}
.restaurent-details-mob span {
  display: block;
  font-weight: 600;
}
/*Restaurent tabs*/

.restaurent-tabs.fix-tab {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 2;
}
.restaurent-tabs .restaurent-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.restaurent-tabs .nav-pills .nav-link {
  margin-right: 15px;
  margin-bottom: 0;
  padding: 10px 0;
  border-bottom: 3px solid transparent;
  border-radius: 0;
}
.restaurent-tabs .nav-pills .nav-link.active {
  border-bottom: 3px solid #141433;
  color: #000;
  opacity: 1;
  background-color: transparent;
}
.restaurent-tabs .restaurent-menu .add-wishlist img {
  width: 20px;
  cursor: pointer;
}
/*promocode*/

.promocodeimg {
  height: 115px;
}
.promocodeimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.promocode-text {
  position: absolute;
  top: 0;
  display: flex;
  right: 0;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 30px;
  flex-wrap: wrap;
  z-index: 1;
}
.promocode-text .promocode-text-content h5,
.promocode-text .promocode-text-content p {
  word-break: break-all;
}
.promocode-btn a {
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 3px;
  padding: 12px 15px;
  font-size: 16px;
  font-weight: 600;
}
/*Restaurent Address*/

.restaurent-address {
  padding: 15px 0;
}
.restaurent-address .address-details .address,
.restaurent-address .address-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.restaurent-address .address-details .address {
  flex-basis: auto;
  flex-grow: 0;
  max-width: 100%;
}
.restaurent-address .address-details .address .delivery-address {
  padding-right: 40px;
}
.restaurent-address .address-details .change-address {
  display: flex;
  flex-direction: column;
  padding: 0 5px 0 15px;
  text-align: right;
  justify-content: center;
}
/*restaurent-ad*/

.restaurent-ad .ad-img {
  height: 350px;
}
.restaurent-ad .ad-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.restaurent-ad .ad-img .content-box {
  padding: 0 60px;
}
/*Restaurent Meals*/

.restaurent-meals {
  position: relative;
}
.restaurent-meals .restaurent-meal-head > .card {
  padding: 0;
  margin-bottom: 25px;
  border: none;
  background: transparent;
}
.restaurent-meals .restaurent-meal-head > .card > .card-header {
  border: none;
  padding: 0.75rem 0 0;
  background: transparent;
}
.restaurent-meals
  .restaurent-meal-head
  > .card
  > .card-header
  > .section-header-left
  > h3
  > .card-link {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  font-size: 25px;
  cursor: pointer;
}
.restaurent-meals
  .restaurent-meal-head
  > .card
  > .card-header
  > .section-header-left
  > h3
  > .card-link:after {
  content: "\f077";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
.restaurent-meals
  .restaurent-meal-head
  > .card
  > .card-header
  > .section-header-left
  > h3
  > .card-link.collapsed:after {
  content: "\f078";
}
.restaurent-meals .restaurent-meal-head > .card > .collapse > .card-body {
  padding: 0;
}
.restaurent-product-list {
  position: relative;
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 10px;
  height: 95%;
}
.restaurent-product-list .restaurent-product-detail {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.restaurent-product-list:hover {
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.2),
    0 1px 5px 0 rgba(67, 41, 163, 0.2);
  transition: 0.5s;
}
.restaurent-product-list .restaurent-product-detail .restaurent-product-left {
  padding: 8px 12px;
  width: 100%;
}
.restaurent-product-list
  .restaurent-product-detail
  .restaurent-product-left
  .restaurent-tags-price,
.restaurent-product-list
  .restaurent-product-detail
  .restaurent-product-left
  .restaurent-product-caption-box,
.restaurent-product-list
  .restaurent-product-detail
  .restaurent-product-left
  .restaurent-product-title-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}
.restaurent-product-list
  .restaurent-product-detail
  .restaurent-product-left
  .restaurent-product-box
  .restaurent-product-label,
.restaurent-product-list
  .restaurent-product-detail
  .restaurent-product-left
  .restaurent-product-box {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.restaurent-product-list
  .restaurent-product-detail
  .restaurent-product-left
  .restaurent-product-box
  .restaurent-product-title {
  margin-right: 15px;
}
.restaurent-product-list
  .restaurent-product-detail
  .restaurent-product-left
  .restaurent-product-caption-box,
.restaurent-product-list
  .restaurent-product-detail
  .restaurent-product-left
  .restaurent-product-box
  .restaurent-product-label {
  align-self: flex-start;
  margin-bottom: 10px;
}
.restaurent-product-list .restaurent-product-detail span {
  word-break: break-all;
}
.restaurent-product-list .restaurent-product-detail .restaurent-tags {
  display: flex;
}
.restaurent-product-list
  .restaurent-product-detail
  .restaurent-product-left
  .restaurent-tags-price {
  align-items: center;
}
.restaurent-product-list .restaurent-product-rating {
  text-align: center;
  padding: 0 10px;
  padding-left: 0;
}
.restaurent-product-list .restaurent-product-rating .ratings {
  display: flex;
}
.restaurent-product-list .restaurent-product-rating .rating-text p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.restaurent-product-list .restaurent-product-img {
  width: 150px;
  flex-basis: 150px;
  line-height: 0;
  height: 100%;
}
.restaurent-product-list .restaurent-product-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.restaurent-product-list
  .restaurent-product-detail
  .restaurent-product-left
  .restaurent-tags-price
  .circle-tag {
  position: absolute;
  top: 8px;
  right: 12px;
}
.sidebar-card.card {
  margin: 4px;
}
.sidebar-card .offer-content {
  text-align: center;
  background-repeat: no-repeat;
  width: 100%;
  padding: 20px 10px 10px;
  position: relative;
  min-height: 100%;
  background: linear-gradient(0deg, #6a1d89a8, #6a1d89a8);
  background-size: cover;
}
.sidebar-card .offer-content h2 {
  text-transform: uppercase;
  font-size: 50px;
}
.sidebar-card .offer-content h2 small {
  display: block;
  font-size: 30px;
  text-transform: lowercase;
}
/*Restaurent About*/

.restaurent-about .about-restaurent {
  margin-top: 10px;
}
.restaurent-about .about-restaurent li {
  margin-bottom: 10px;
  position: relative;
}
.restaurent-about .about-restaurent li i {
  position: absolute;
  left: 0;
  top: 5px;
  font-size: 16px;
}
.restaurent-about .about-restaurent li span {
  position: relative;
  left: 30px;
}
.restaurent-schdule .card,
.restaurent-schdule .card .card-header {
  border: none;
  border-radius: 0;
}
.restaurent-schdule .card .card-body {
  padding: 20px 20px 10px 20px;
}
.restaurent-schdule .card .card-body .schedule-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.restaurent-schdule .card .card-footer {
  background: #fff;
}
.social-media {
  display: flex;
  align-items: center;
}
.social-media li {
  width: 20%;
  border: 1px solid #ccc;
  border-radius: 0;
  border-right: 0;
}
.social-media li:last-child {
  border: 1px solid #ccc;
}
.social-media li a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
}
.social-media li:hover {
  background: #141433;
  transition: 0.5s;
}
.social-media li:hover a {
  color: #fff;
}
/*Restaurent Review*/

.restaurent-review ul {
  display: flex;
  margin: 0 -15px 20px;
}
.restaurent-review ul li {
  padding: 0 15px;
  max-width: 110px;
}
.restaurent-review .review-img {
  max-width: 540px;
  margin: 0 auto;
  text-align: center;
  padding: 30px;
  padding-bottom: 0;
}
.restaurent-review .review-img img {
  margin-bottom: 30px;
}
.review-box {
  padding: 0 0 10px 0;
}
.review-box.comment-reply {
  margin-left: 60px;
}
.review-box .review-user {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.review-box .ratings,
.review-box .review-user {
  margin-bottom: 10px;
}
.review-box .food-order {
  margin-bottom: 5px;
  display: block;
}
.review-box .review-user .review-user-img {
  display: flex;
  align-items: center;
}
.review-box .review-user .review-user-img img {
  margin-right: 12px;
}
.review-box .review-user .review-user-img .reviewer-name p {
  margin-bottom: 5px;
  line-height: normal;
  font-size: 16px;
  font-weight: 700;
}
.review-box .review-user .review-user-img .reviewer-name p small {
  margin-left: 10px;
}
.review-box .review-user .review-user-img .reviewer-name span {
  font-size: 12px;
  vertical-align: middle;
  margin-left: 3px;
}
.review-box .review-user .review-date span {
  font-size: 14px;
  color: #464545;
  font-weight: 600;
}
.review-box .food {
  display: flex;
  flex-wrap: wrap;
  margin: auto 0;
}
.review-box .food li {
  max-width: 100%;
  padding: 0;
}
.review-box .food li button.add-pro {
  cursor: pointer;
  margin-right: 10px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  text-transform: capitalize;
  padding: 3px 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  position: relative;
  padding-right: 35px;
  font-weight: 500;
  margin-bottom: 10px;
}
.review-box .food li button.add-pro span.close {
  position: absolute;
  right: 10px;
  font-weight: 700;
  font-size: 22px;
  top: 50%;
  transform: translate(0px, -50%);
  text-align: right;
  padding: 0;
  color: #fff;
  font-weight: bold;
  opacity: 1;
}
.restaurent-popup .modal-content {
  min-height: 85vh;
  background: #fbfbfc;
  overflow-y: auto;
}
.restaurent-popup .modal-header {
  display: block;
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
  padding: 0;
  height: 200px;
}
.restaurent-popup .modal-header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.restaurent-popup .close {
  margin: 0;
  display: block;
  width: 100%;
  text-align: left;
  color: #141433;
  opacity: 1;
  padding: 0;
  font-size: 35px;
  font-weight: 200;
  line-height: 1;
  margin-bottom: 20px;
  position: absolute;
  padding: 20px;
}
.restaurent-popup .modal-title {
  font-size: 20px;
}
.restaurent-popup .modal-body {
  padding: 0;
  height: 550px;
  overflow: auto;
}
.product-quantity {
  display: flex;
  align-items: center;
}
.product-quantity span {
  margin-right: 20px;
}
.product-quantity .quantity {
  border: 1px solid #545470;
  border-radius: 19px;
  flex-grow: 0;
  overflow: hidden;
  position: relative;
  height: 38px;
  width: auto;
}
.product-quantity .quantity button {
  padding: 0 15px;
}
.product-quantity .quantity button:focus {
  outline: none;
}
.product-quantity .quantity input {
  border: none;
  width: 40px;
}
/*additional-product*/

.additional-product .card {
  border-radius: 0;
  border: none;
}
.additional-product .card .card-header {
  border-radius: 0;
  background: transparent;
  padding: 10px;
}
.additional-product .card .card-header .card-link {
  font-size: 18px;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 0;
}
.additional-product .card .card-header .card-link span {
  display: block;
}
.additional-product .card .card-header .card-link:after {
  content: "\f077";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 25px;
}
.additional-product .card .card-header .card-link.collapsed:after {
  content: "\f078";
}
.additional-product .card .card-body button.add-pro {
  cursor: pointer;
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.87);
  background: rgba(0, 0, 0, 0.05);
  text-transform: capitalize;
  padding: 3px 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  position: relative;
  padding-right: 35px;
  font-weight: 500;
  margin-bottom: 10px;
}
.additional-product .card .card-body button.add-pro:hover {
  background: #ccc;
}
.additional-product .card .card-body button.add-pro span.close {
  position: absolute;
  right: 10px;
  font-weight: 700;
  font-size: 22px;
  top: 50%;
  transform: translate(0px, -50%);
  text-align: right;
  padding: 0;
  color: #000;
  font-weight: bold;
}
.restaurent-popup .modal-footer {
  justify-content: space-between;
}
/*map-gallery-se*/

.map-gallery-sec .main-box #locmap {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  z-index: 0;
  pointer-events: none;
  border: none;
}
.map-gallery-sec .map-pl-0 {
  padding-left: 0;
}
.map-gallery-sec .map-pr-0 {
  padding-right: 0;
}
.map-gallery-sec .gallery-box .gallery-img {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  margin: -5px;
}
.map-gallery-sec .gallery-box .gallery-img li {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 5px;
}
.smoothscroll.active {
  position: relative;
  padding-top: 150px;
}
.smoothscroll.active#menu {
  padding-top: 0;
}
/*video box*/

.video-box iframe {
  width: 100%;
  border: none;
  height: 250px;
}
.video_wrapper {
  width: 100%;
  margin: 0 auto;
  position: relative;
  background-color: #000;
  height: 250px;
}
.videoIframe {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
}
.videoPoster {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  border: none;
  background-position: center;
  overflow: hidden;
  opacity: 1;
  transition: opacity 100ms, height 0s;
  transition-delay: 0s, 0s;
}
.video_wrapper:hover .video-inner i {
  color: #141433;
  transition: 0.5s;
}
.videoPoster:hover {
  cursor: pointer;
}
.videoPoster .video-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.videoPoster .video-inner i {
  font-size: 70px;
  color: #fff;
}
.videoWrapperActive .videoPoster {
  opacity: 0;
  height: 0;
  transition-delay: 0s, 800ms;
}
.discount-box {
  margin-top: -6px;
}
.discount-box .discount-price .right-side,
.discount-box .discount-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.discount-box .discount-price .right-side .dis-text {
  margin-right: 25px;
}
.discount-box .discount-price .right-side,
.discount-box .discount-price .left-side {
  max-width: 50%;
  flex-basis: 50%;
}
/*side bar cart*/

.sidebar .cart-detail-box {
  width: 100%;
  background: #fff;
  position: relative;
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
  border-radius: 3px;
  cursor: pointer;
}
.sidebar .cart-detail-box > .card {
  border: none;
}
.sidebar .cart-detail-box > .card .card-header,
.sidebar .cart-detail-box > .card .card-footer {
  background: #fff;
}
.sidebar .cart-detail-box > .card .card-body {
  height: 350px;
  overflow-y: auto;
}
.sidebar .cart-detail-box > .card > .modify-order > .total-amount,
.sidebar .cart-detail-box > .card .card-body .item-total .total-price,
.sidebar .cart-detail-box > .card .card-body .cat-product-box .cat-product {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #ccc;
}
.sidebar
  .cart-detail-box
  > .card
  .card-body
  .cat-product-box
  .cat-product
  .cat-name
  a
  p {
  margin-bottom: 0px;
}
.sidebar
  .cart-detail-box
  > .card
  .card-body
  .cat-product-box
  .cat-product
  .cat-name
  a
  p
  span {
  margin-right: 10px;
}
.sidebar
  .cart-detail-box
  > .card
  .card-body
  .cat-product-box
  .cat-product
  .cat-name {
  font-size: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.sidebar .cart-detail-box > .card > .modify-order > .total-amount {
  background-color: #ba1c24;
}
/*======================
6. Add Restaurent
========================*/

/*add restaurent*/

.register-restaurent-sec {
  counter-reset: section;
}
#add-restaurent-tab .step-steps > li:after {
  counter-increment: section;
  content: counter(section);
  position: absolute;
  top: 16px;
  font-size: 10px;
  left: 0;
  right: 0;
  pointer-events: none;
  font-weight: 600;
}
#add-restaurent-tab .step-steps > li.done:after,
#add-restaurent-tab .step-steps > li.active:after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #fff;
  font-size: 7px;
  top: 20px;
}
#add-restaurent-tab .step-footer .none {
  display: none;
}
#add-restaurent-tab .step-footer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding-top: 15px;
  margin-bottom: 0px;
}
#add-restaurent-tab .step-footer #next-1 {
  margin: 0px 0px 0px auto;
}
#add-restaurent-tab .steps-1 {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding-left: 0;
}
.step-app > .steps-1 {
  overflow: hidden;
  margin: 0 auto;
}
#add-restaurent-tab .steps-1 > li {
  flex: 0 0 25%;
  max-width: 25%;
}
.step-app .steps-1 > li {
  display: inline-block;
  position: relative;
  z-index: 1;
  text-align: center;
}
#add-restaurent-tab .steps-1 > li:before {
  background-color: #e3e3e3;
  height: 12px;
  left: 45%;
  top: 19px;
  width: 100%;
  transition: all 0.3s;
  margin-left: 4px;
}
#add-restaurent-tab .steps-1 > li:first-child:before {
  border-radius: 10px 0 0 10px;
}
.step-app .steps-1 > li:before {
  position: absolute;
  content: "";
  z-index: -1;
  width: 100%;
  height: 2px;
  background-color: #c4cad2;
  top: 35px;
  left: 85px;
}
.step-app .steps-1 > li > a,
.step-app .steps-1 > li > a > span {
  display: block;
}
#add-restaurent-tab .steps-1 > li > a > .number {
  width: 20px;
  height: 20px;
  background-color: #b6b6b6;
  border: 0px solid;
  transition: all 0.3s;
}
.step-app .steps-1 > li > a > .number {
  border-radius: 50%;
  color: #b6b6b6;
  font-size: 18px;
  font-weight: bold;
  line-height: 50px;
  margin: 10px auto;
  position: relative;
  z-index: 0;
  top: 5px;
}
#add-restaurent-tab .steps-1 > li.active > a > .number,
#add-restaurent-tab .steps-1 > li.done > a > .number {
  background-color: #003d00;
}
#add-restaurent-tab .steps-1 > li > a > .step-name {
  font-weight: 600;
  color: #141433;
  font-size: 12px;
  margin-top: 20px;
  transition: all 0.3s;
}
#add-restaurent-tab .steps-1 > li:last-child:before {
  width: 0;
}
#add-restaurent-tab .steps-1 > li.done:before {
  background-color: #00a000;
}
#add-restaurent-tab .steps-2 {
  margin: 20px 0;
}
#add-restaurent-tab .steps-2 > li {
  display: block;
  margin-bottom: 10px;
}
#add-restaurent-tab .steps-2 > li:before,
#add-restaurent-tab .steps-2 > li:after {
  display: none;
}
#add-restaurent-tab .steps-2 > li > a.add-res-tab {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 10px 16px;
  font-size: 14px;
  line-height: 1.595;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
  border-color: rgba(67, 41, 163, 0.2);
  background: #fff;
  font-weight: 600;
}
#add-restaurent-tab .steps-2 > li.done a.add-res-tab,
#add-restaurent-tab .steps-2 > li.active a.add-res-tab {
  color: #fff;
  background: #141433;
  border-color: #141433;
}
#add-restaurent-tab.step-app .step-content .step-tab-panel {
  display: none;
}
#add-restaurent-tab.step-app .step-content .step-tab-panel.active {
  display: block;
}
#add-restaurent-tab.step-app .step-content .step-tab-panel label sup {
  top: 0;
  color: #ff0000;
}
#add-restaurent-tab.step-app
  .step-content
  .step-tab-panel
  .form-group
  .form-group {
  display: flex;
  margin-bottom: 35px;
}
.package-box .package-caption {
  border-radius: 0 0 3px 3px;
}
.package-box .package-caption .package-item-first {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.package-box .package-caption > ul > li:before {
  content: "";
  position: absolute;
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #fff;
  left: 0;
  top: 50%;
  margin-top: 4px;
}
.package-box .package-caption > ul > li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 12px;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid rgba(210, 201, 255, 0.1);
  position: relative;
  padding-left: 15px;
  letter-spacing: 0.4px;
}
.package-box .package-caption > ul > li:first-child {
  border: none;
}
.package-box .package-caption .package-btn {
  margin-top: 20px;
}
.general-sec iframe {
  border: none;
}
.payment-sec .card-header,
.payment-sec .card {
  border: none;
  border-radius: 0;
}
.payment-sec .card-header {
  background: #fff;
  padding-left: 0;
  padding-top: 0;
}
.payment-sec .card-header .card-link.collapsed:before {
  content: "+";
}
.payment-sec .card-header .card-link:before {
  content: "-";
  font-size: 18px;
  line-height: normal;
  position: relative;
  top: 2px;
  right: 0;
  padding-right: 10px;
  font-weight: 700;
}
.driver-tip-sec > .nav,
.payment-option-tab > .nav {
  margin-bottom: 1rem;
}
.payment-option-tab > .nav > .nav-item {
  flex: 0 0 20%;
  max-width: 20%;
}
.driver-tip-sec > .nav > .nav-item > .nav-link,
.payment-option-tab > .nav > .nav-item > .nav-link {
  padding: 0.5rem;
  text-align: center;
  border: 1px solid rgba(67, 41, 163, 0.2);
  border-right: 0;
  border-radius: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.driver-tip-sec > .nav > .nav-item:last-child > .nav-link,
.payment-option-tab > .nav > .nav-item:last-child > .nav-link {
  border: 1px solid rgba(67, 41, 163, 0.2);
}
.driver-tip-sec > .nav > .nav-item > .nav-link.active,
.payment-option-tab > .nav > .nav-item > .nav-link.active {
  color: #fff;
  background: #141433;
  border-color: #141433;
}
.payment-option-tab .credit-card:before {
  content: "";
  position: absolute;
  padding: 15px 25px;
  font-size: 18px;
  color: #6b6b83;
  pointer-events: none;
  background-image: url(../img/visa.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  top: 8px;
  left: 8px;
}
.payment-option-tab .credit-card.card-front:before {
  background-image: url(../img/card-front.jpg);
}
.payment-option-tab .credit-card.card-back:before {
  background-image: url(../img/card-back.jpg);
}
.payment-option-tab .credit-card.gift-card:before {
  background-image: url(../img/gift-box.png);
}
.payment-option-tab .credit-card.promocode:before {
  background-image: url(../img/promo.jpg);
}
.payment-option-tab .credit-card.amex-card-front:before {
  background-image: url(../img/amex-card-front.png);
}
.payment-option-tab .credit-card.amex-card-back:before {
  background-image: url(../img/amex-card-back.png);
}
.payment-option-tab .credit-card input {
  padding-left: 65px;
}
.driver-tip-sec > .nav > .nav-item {
  flex: 0 0 50%;
  max-width: 50%;
}
.driver-tip-sec .tip-percentage form {
  display: flex;
  flex-wrap: wrap;
}
.driver-tip-sec .tip-percentage input[type="radio"]:checked + span {
  background: #141433;
  color: #fff;
}
.driver-tip-sec .tip-percentage label {
  position: relative;
  flex: 0 0 25%;
  max-width: 25%;
  z-index: 1;
  cursor: pointer;
}
.driver-tip-sec .tip-percentage label span {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding: 13px 0.5rem;
  z-index: -1;
  text-align: center;
  border: 1px solid rgba(67, 41, 163, 0.2);
  border-right: 0;
  border-radius: 0;
}
.driver-tip-sec .tip-percentage label input {
  -webkit-appearance: none;
}
.driver-tip-sec .tip-percentage label:last-child span {
  border: 1px solid rgba(67, 41, 163, 0.2);
}
.driver-tip-sec .custom-tip .input-group .input-group-prepend {
  border: 1px solid #8f8fa1;
}
.driver-tip-sec .custom-tip .input-group .input-group-prepend span {
  border-radius: 0;
}
.thankmsg-sec .msg-wrapper h1 {
  font-size: 60px;
}
.thankmsg-sec .msg-wrapper .contact-details {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  flex-wrap: wrap;
}
.thankmsg-sec .msg-wrapper .contact-details > li {
  font-size: 16px;
  margin: 0 25px;
}
.thankmsg-sec .msg-wrapper .contact-details > li > i {
  padding-right: 15px;
}
/*======================
7. Add Restaurent
========================*/

/*======================
3. List View
========================*/

.most-popular .popular-item-slider .swiper-slide {
  margin-bottom: 20px;
}
.sort-tag-filter,
.product-list-view,
.most-popular .popular-item-slider {
  border-bottom: 1px solid rgba(67, 41, 163, 0.1);
}
.sort-tag-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.restaurent-tags span.tags {
  cursor: pointer;
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.87);
  background: rgba(0, 0, 0, 0.05);
  text-transform: capitalize;
  padding: 3px 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  position: relative;
  padding-right: 35px;
  font-weight: 500;
}
.restaurent-tags span.tags:hover {
  color: #141433;
}
.restaurent-tags span.tags span.close {
  position: absolute;
  right: 10px;
  font-weight: 700;
  font-size: 14px;
  top: 50%;
  transform: translate(0px, -50%);
}
.sorting span {
  margin-right: 10px;
}
.sorting select {
  color: #00000059;
  border: 2px solid #ccc;
  padding: 3px 40px 3px 8px;
  border-radius: 3px;
  text-align: left;
  font-weight: 600;
}
/*filter-sidebar*/

.filter-sidebar .title-2 small {
  margin-left: 10px;
}
.filter-sidebar .title-2 small a {
  text-decoration: underline;
}
.filter-sidebar .sidebar-tab > ul > li {
  margin-right: 28px;
  border-radius: 0;
}
.filter-sidebar .sidebar-tab > ul > li > a {
  color: rgba(0, 0, 0, 0.55);
  border-radius: 0;
  border-bottom: 4px solid transparent;
  font-weight: 700;
  text-align: left;
  padding-left: 0;
  padding-right: 0;
}
.filter-sidebar .sidebar-tab > ul > li > a.active {
  background: transparent;
  color: #000;
  border-bottom: 4px solid #141433;
}
.filter-sidebar .sidebar-tab .tab-content .siderbar-innertab > ul {
  display: flex;
  margin-bottom: 10px;
}
.filter-sidebar .sidebar-tab .tab-content .siderbar-innertab > ul > li {
  width: 50%;
}
.filter-sidebar .sidebar-tab .tab-content .siderbar-innertab > ul > li > a {
  flex: 1;
  white-space: normal;
  border: 1px solid transparent;
  transition-property: z-index, background, border-color, color;
  transition-timing-function: ease;
  transition-duration: 0.1s;
  border-color: rgba(67, 41, 163, 0.2);
  color: #141433;
  background-color: transparent;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
  padding: 4px 0;
}
.filter-sidebar
  .sidebar-tab
  .tab-content
  .siderbar-innertab
  > ul
  > li
  > a.disabled {
  color: #ccc;
}
.filter-sidebar
  .sidebar-tab
  .tab-content
  .siderbar-innertab
  > ul
  > li
  > a.active {
  border-color: #141433;
  color: #fff;
  background-color: #141433;
}
.filter-sidebar
  .sidebar-tab
  .tab-content
  .siderbar-innertab
  > ul
  > li:first-child
  > a {
  border-radius: 4px 0 0 4px;
}
.filter-sidebar
  .sidebar-tab
  .tab-content
  .siderbar-innertab
  > ul
  > li:last-child
  > a {
  border-radius: 0px 4px 4px 0px;
}
.filter-sidebar .sidebar-tab .tab-content p.delivery-type a:before {
  content: "";
  width: 2px;
  height: 2px;
  background: #000;
  display: inline-flex;
  margin: 0 8px;
  vertical-align: middle;
}
.filter-sidebar .sidebar-tab .filters .card-header,
.filter-sidebar .sidebar-tab .filters .card {
  border: none;
  padding: 0.75rem 0;
  background: #fff;
}
.filter-sidebar .sidebar-tab .filters .card {
  padding: 0;
  margin-bottom: 25px;
}
.filter-sidebar .sidebar-tab .filters .card .card-body {
  padding: 0;
}
.filter-sidebar .sidebar-tab .filters .card-header a {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
}
.filter-sidebar .sidebar-tab .filters .card-header a:after {
  content: "\f077";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
.filter-sidebar .sidebar-tab .filters .card-header a.collapsed:after {
  content: "\f078";
}
.filters .rating {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filters .rating button {
  border: 1px solid #ccc;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 19px;
  white-space: nowrap;
  width: 100%;
  text-align: center;
  display: block;
  border-right: 0;
  font-weight: 900;
}
.filters .rating button:hover {
  background: #141433;
  color: #fff;
  border-color: #141433;
}
.filters .rating button:first-child {
  border-radius: 3px 0 0 3px;
}
.filters .rating button:last-child {
  border-radius: 0px 3px 3px 0px;
  border-right: 1px solid #ccc;
}
/*testimonal layout*/

.swiper-slide .testimonial-wrapper {
  margin-top: 0;
}
.video-box,
.testimonial-wrapper .testimonial-box {
  position: relative;
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
  border-radius: 3px;
  cursor: pointer;
}
.testimonial-wrapper .testimonial-box .testimonial-img .brand-logo {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
  background: #fff;
  width: 50px;
  height: 50px;
  transform: translate(15px, -50%);
}
.testimonial-wrapper .testimonial-box .testimonial-img .add-fav {
  position: absolute;
  right: 0;
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
  background: #fff;
  width: 35px;
  height: 30px;
  transform: translate(-15px, 0%);
  bottom: -15px;
  border-radius: 5px 5px 0 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
.testimonial-wrapper .testimonial-box .testimonial-img .add-fav > img {
  padding: 8px;
}
.testimonial-wrapper .testimonial-box .testimonial-user-box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.testimonial-wrapper .testimonial-box .testimonial-user-box img {
  margin-right: 12px;
  margin-bottom: 8px;
}
.testimonial-wrapper
  .testimonial-box
  .testimonial-user-box
  .testimonial-user-name
  p {
  margin-bottom: 0px;
  line-height: normal;
}
.testimonial-wrapper
  .testimonial-box
  .testimonial-user-box
  .testimonial-user-name
  span {
  font-size: 11px;
  margin-left: 3px;
}
/*list view*/

.product-list-view:first-child {
  border-top: 1px solid rgba(67, 41, 163, 0.1);
}
.product-list-view {
  padding: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.product-list-view:hover {
  background: rgba(0, 0, 0, 0.05);
  transition: 0.5s;
}
.product-list-view .product-list-info {
  width: 90px;
}
.product-list-view .product-right-col {
  width: 95%;
}
.product-list-view .product-list-title h6 {
  margin-bottom: 5px;
}
.product-list-view .product-list-info .product-list-img {
  flex: 0 0 90px;
  overflow: hidden;
  height: 90px;
}
.product-list-view .product-list-bottom,
.product-list-view .product-list-details {
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.product-list-view .product-list-details .product-list-title {
  align-content: space-between;
  padding-left: 20px;
  max-width: 100%;
}
.product-list-view .product-list-details .product-detail-right-box {
  display: flex;
  justify-content: space-between;
  width: 60%;
}
.product-list-view
  .product-list-details
  .product-detail-right-box
  .product-list-tags {
  display: flex;
  flex-wrap: wrap;
}
.product-list-view .product-right-col .product-list-bottom .mob-tags-label {
  display: none;
}
.product-list-view
  .product-list-details
  .product-detail-right-box
  .product-list-label
  span {
  margin: 0;
  margin-bottom: 2px;
}
.product-list-view
  .product-list-details
  .product-detail-right-box
  .product-list-price
  .product-list-time {
  display: none;
}
.product-list-view .product-list-details .product-list-price {
  text-align: right;
}
.product-list-view .product-list-time,
.product-list-view .product-list-time ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.product-list-view .product-list-time ul li {
  padding: 0 10px;
  position: relative;
}
.product-list-view .product-list-time ul li:after {
  content: "";
  background: #6b6b83;
  width: 3px;
  height: 3px;
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0px, -50%);
}
.product-list-view .product-list-time ul li:last-child {
  padding-right: 0;
}
.product-list-view .product-list-time ul li:last-child:after {
  display: none;
}
.product-list-view .product-list-bottom .product-list-type {
  padding-left: 20px;
}
.product-list-view .product-list-bottom .product-list-type span {
  display: inline-block;
}
.product-list-view .product-list-time {
  width: 27%;
  justify-content: space-between;
}
.product-list-view .product-list-time > span {
  margin-right: 20px;
}
/*=========================
8. Login & register
===========================*/

.user-page .login-sec {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translate(0px, -50%);
}
.user-page .login-sec .login-box {
  max-width: 420px;
  width: auto;
  margin: 0 auto;
  margin-top: 0px;
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
  border-radius: 3px;
  background: #fff;
  padding: 30px;
}
.user-page .login-sec .login-box .checkbox-reset {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user-page .login-sec .login-box span.terms {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.user-page .login-sec .login-box span.terms a {
  margin: 0 3px;
}
.user-page .login-sec .login-box .form-group .field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -28px;
  position: relative;
  z-index: 2;
  right: 18px;
  cursor: pointer;
}
/*=========================
9. Checkout
===========================*/

.recipt-sec,
.tracking-sec {
  display: flex;
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
  border-radius: 3px;
  background: #fff;
  margin-bottom: 20px;
}
.recipt-sec {
  display: block;
  margin-bottom: 0;
}
.tracking-sec .tracking-map,
.tracking-sec .tracking-details {
  flex: 0 0 50%;
  max-width: 50%;
}
#add-listing-tab1 .step-steps,
#add-listing-tab .step-steps {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding-left: 0;
}
.step-app > .step-steps {
  overflow: hidden;
  margin: 0 auto;
}
#add-listing-tab1 .step-steps > li,
#add-listing-tab .step-steps > li {
  flex: 0 0 25%;
  max-width: 25%;
}
.step-app > .step-steps > li {
  display: inline-block;
  position: relative;
  z-index: 1;
  text-align: center;
}
#add-listing-tab1 .step-steps > li:before,
#add-listing-tab .step-steps > li:before {
  background-color: #e3e3e3;
  height: 12px;
  left: 45%;
  top: 19px;
  width: 100%;
  transition: all 0.3s;
  margin-left: 8px;
}
#add-listing-tab1 .step-steps > li:first-child:before,
#add-listing-tab .step-steps > li:first-child:before {
  border-radius: 10px 0 0 10px;
}
.step-app > .step-steps > li:before {
  position: absolute;
  content: "";
  z-index: -1;
  width: 100%;
  height: 2px;
  background-color: #c4cad2;
  top: 35px;
  left: 85px;
}
.step-app > .step-steps > li > a,
.step-app > .step-steps > li > a > span {
  display: block;
}
#add-listing-tab1 .step-steps > li > a > .number,
#add-listing-tab .step-steps > li > a > .number {
  width: 12px;
  height: 12px;
  background-color: #b6b6b6;
  border: 0px solid;
  transition: all 0.3s;
}
.step-app > .step-steps > li > a > .number {
  border-radius: 50%;
  color: #b6b6b6;
  font-size: 18px;
  font-weight: bold;
  line-height: 50px;
  margin: 10px auto;
  position: relative;
  z-index: 0;
  top: 9px;
}
#add-listing-tab1 .step-steps > li.active > a > .number,
#add-listing-tab1 .step-steps > li.done > a > .number,
#add-listing-tab .step-steps > li.active > a > .number,
#add-listing-tab .step-steps > li.done > a > .number {
  background-color: #003d00;
}
#add-listing-tab1 .step-steps > li > a > .step-name,
#add-listing-tab .step-steps > li > a > .step-name {
  font-weight: 600;
  color: #141433;
  font-size: 12px;
  margin-top: 20px;
  transition: all 0.3s;
  white-space: normal;
}
#add-listing-tab1 .step-steps > li:last-child:before,
#add-listing-tab .step-steps > li:last-child:before {
  width: 0;
}
#add-listing-tab1 .step-steps > li.done:before,
#add-listing-tab .step-steps > li.done:before {
  background-color: #00a000;
}
.tracking-sec .tracking-map #pickupmap {
  height: 100%;
  width: 100%;
  min-height: 300px;
}
.tracking-sec .tracking-details .fullpageview {
  position: absolute;
  bottom: 20px;
}
.md-modal {
  margin: auto;
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  width: 100%;
  min-width: 320px;
  height: 100%;
  z-index: 2000;
  visibility: hidden;
  backface-visibility: hidden;
}
.md-show {
  visibility: visible;
}
.md-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  visibility: hidden;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0;
  background: #fff;
  transition: all 0.3s;
}
.md-show ~ .md-overlay {
  opacity: 1;
  visibility: visible;
}
.md-effect-12 .md-content {
  transform: scale(0.8);
  opacity: 0;
  transition: all 0.3s;
  height: 100%;
}
.md-show.md-effect-12 ~ .md-overlay {
  background-color: #fff;
}
.md-show.md-effect-12 .md-content {
  transform: scale(1);
  opacity: 1;
}
.md-modal .md-content #pickupmap2 {
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.md-modal .md-content .modal-sidebar #add-listing-tab1 .step-steps > li:after {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #00a000;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  position: absolute;
  top: 0;
}
.md-modal
  .md-content
  .modal-sidebar
  #add-listing-tab1
  .step-steps
  > li.order-note:after {
  content: "\f328";
}
.md-modal
  .md-content
  .modal-sidebar
  #add-listing-tab1
  .step-steps
  > li.make-order:after {
  content: "\f447";
}
.md-modal
  .md-content
  .modal-sidebar
  #add-listing-tab1
  .step-steps
  > li.bike:after {
  content: "\f21c";
}
.md-modal
  .md-content
  .modal-sidebar
  #add-listing-tab1
  .step-steps
  > li.home-delivery:after {
  content: "\f015";
}
.md-modal .md-content .modal-sidebar .arrow {
  position: absolute;
  right: 50px;
}
.md-modal .md-content .modal-sidebar .rating-box .ratings span {
  cursor: pointer;
}
.md-modal .md-content .modal-sidebar .rating-box .ratings span:hover i {
  color: #141433;
}
.recipt-sec .recipt-name.title {
  align-items: center;
  padding-bottom: 20px;
}
.countdown-box {
  display: flex;
}
.countdown-box .time-box {
  text-align: center;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  color: #141433;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  width: 100px;
  height: 100px;
  border-radius: 3px;
  font-size: 28px;
  font-weight: 600;
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
}
.countdown-box .time-box span {
  line-height: 1;
}
.countdown-box .time-box span + span {
  font-size: 16px;
  margin-top: 5px;
}
.countdown-box .time-box span h6 {
  margin-top: 5px;
  color: #000000;
}
.home-2 .countdown-box .time-box:last-child {
  margin-right: 0;
}
.recipt-sec .recipt-name span {
  display: block;
}
.checkout-product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}
.checkout-product .img-name-value {
  display: flex;
  align-items: center;
}
.checkout-product .img-name-value .product-value {
  margin: 0 30px;
}
.payment-method .method-type {
  display: flex;
  align-items: center;
}
.payment-method .method-type i {
  font-size: 40px;
  margin-right: 15px;
}
.price-table .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.checkout-page .help-btn {
  margin: 0px 0;
}
.checkout-page .d-flex {
  align-items: center;
  justify-content: center;
}
/*=========================
10. Final Order
===========================*/

.final-order .title2 span {
  margin-left: 140px;
}
.final-order .advertisement-img,
.final-order .advertisement-img img {
  height: 100%;
}
/*=========================
11. Geo Locator
===========================*/

.inner-wrapper {
  height: inherit;
  position: relative;
}
.main-padding {
  padding: 64px 100px;
}
.section-2,
.main-banner.video-banner,
.main-banner {
  height: 100vh;
}
.main-banner .main-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.main-banner .footer-img {
  position: absolute;
  bottom: 0;
  max-width: 340px;
  right: 0;
}
.inner-wrapper .main-page .top-nav {
  position: absolute;
  top: 64px;
  display: flex;
  right: 70px;
}
.inner-wrapper .main-page .top-nav h6 {
  margin-right: 30px;
}
.inner-wrapper .main-page .login-box {
  height: 100%;
  display: flex;
  align-items: center;
}
.inner-wrapper .main-page .login-box .input-group-prepend {
  height: 47px;
  min-width: 44px;
  flex-basis: 44px;
  border: 1px solid #8f8fa1;
  border-left: 0;
  border-radius: 0px 4px 4px 0;
  display: none;
}
.inner-wrapper .main-page .login-box .input-group-prepend .input-group-text {
  line-height: 1.5;
  font-size: 20px;
  background: transparent;
  border: none;
}
.inner-wrapper .main-page .login-box .input-group .input-group2 {
  display: flex;
}
.inner-wrapper .main-page .login-box .input-group {
  margin: 0 -4px;
}
.inner-wrapper .main-page .login-box .input-group .input-group2,
.inner-wrapper .main-page .login-box .input-group-append {
  padding: 0 8px;
}
.inner-wrapper .main-page .login-box .input-group-append button {
  white-space: normal;
}
/*=========================
12. About Us
===========================*/

/*slider*/

.about-us-slider .slide-item {
  height: auto;
}
.about-us-slider .slide-item img {
  width: 100%;
  object-position: center;
  height: auto;
}
.about-us-slider .slide-item .overlay-bg-dark {
  z-index: 0;
  background: #000000;
  opacity: 0;
}
.about-us-slider .slide-item .right-side-content h1,
.about-us-slider .slide-item .left-side-content h1 {
  font-size: 60px;
  line-height: 1.2;
  text-transform: capitalize;
}
.about-us-slider .slide-item .right-side-content {
  transition: all 1s linear;
  transition-delay: 1s;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
}
.about-us-slider .slide-item .right-side-content a,
.about-us-slider .slide-item .left-side-content a {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}
.about-us-slider .slide-item .left-side-content {
  transition: all 1s linear;
  transition-delay: 1s;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
}
.about-us-slider .slide-item .right-side-content p,
.about-us-slider .slide-item .left-side-content p {
  max-width: 650px;
  margin: 0 auto 8px;
  font-size: 16px;
}
.about-us-slider .slide-item .right-side-content.far-right {
  margin: auto 0 0 0;
}
.border-butn {
  padding: 9px 35px;
  font-size: 1rem;
  border: 1px solid #fff;
  letter-spacing: 0.5px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.75rem;
  color: #fff;
  transition: all 0.5s ease;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  z-index: 1;
}
.border-butn:hover {
  background-color: #141433;
  border-color: #141433;
  color: #fff;
}
.text-custom-pink {
  color: #ba1c24;
}
/*aboutus*/

.aboutus .history-title {
  margin-top: -7px;
}
.aboutus .history-title > a {
  margin-top: 20px;
}
/*how it works*/

.how-it-works .how-it-works-box {
  text-align: center;
  padding: 20px 0px;
  cursor: pointer;
}
.how-it-works .how-it-works-box.arrow-1:after {
  content: "";
  width: 100%;
  background: url(../img/arrow-2.svg) no-repeat;
  position: absolute;
  right: 0;
  z-index: 1;
  background-size: contain;
  background-position: right;
  height: 70px;
  top: -35px;
  transform: rotate(50deg);
  left: 65px;
}
.how-it-works .how-it-works-box.arrow-2:after {
  content: "";
  width: 100%;
  background: url(../img/arrow-3.svg) no-repeat;
  position: absolute;
  right: 0;
  z-index: 1;
  background-size: contain;
  background-position: right;
  height: 70px;
  top: -20px;
  transform: rotate(35deg);
  left: 50px;
}
.how-it-works .how-it-works-box .how-it-works-box-inner {
  max-width: 250px;
  margin: 0 auto;
}
.how-it-works .how-it-works-box .how-it-works-box-inner h6,
.how-it-works .how-it-works-box .how-it-works-box-inner .icon-box {
  margin-bottom: 15px;
}
.how-it-works .how-it-works-box .how-it-works-box-inner .icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #141433;
  margin: 0 auto 20px;
  position: relative;
}
.how-it-works .how-it-works-box .how-it-works-box-inner .icon-box img {
  padding: 30px;
}
.how-it-works .how-it-works-box .how-it-works-box-inner .icon-box .number-box {
  position: absolute;
  top: 0;
  left: 10px;
  background: #ba1c24;
  color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  font-size: 12px;
}
/*feedback-area-two*/

.feedback-area-two {
  text-align: center;
  position: relative;
  z-index: 1;
  opacity: 0.9;
  background-image: url(../img/footer-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.feedback-area-two .feedback-item-two {
  max-width: 800px;
  margin: 15px auto 0;
}
.feedback-area-two .feedback-item-two img {
  width: 80px;
  height: 80px;
  margin: auto;
  border-radius: 50%;
  border: 4px solid #ffffff;
  margin-bottom: 17px;
}
.feedback-area-two .feedback-item-two p {
  font-style: italic;
  margin-bottom: 15px;
}
.feedback-area-two .feedback-item-two span {
  line-height: 1;
  display: block;
  margin-top: 9px;
}
/* fixes */

.side-bar .trending-blog-cat li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0px;
}
.blog-meta-author {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.blog-meta-author p {
  position: relative;
  display: flex;
  align-items: center;
}
.blog-meta-author p > span {
  width: 40px;
  display: block;
  float: left;
  height: 40px;
}
.blog-meta-author p a {
  display: block;
  line-height: 1.5;
  color: #464545;
  font-weight: 600;
}
.btn-second-2 {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid;
  padding: 9px 15px 8px;
  font-size: 14px;
  line-height: 1.51741;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  vertical-align: middle;
  white-space: nowrap;
  font-weight: 500;
  position: relative;
}
.delivery-slider .irs--flat.irs-with-grid {
  width: 95%;
  margin: 0 auto;
}
.delivery-slider .irs--flat .irs-line,
.delivery-slider .irs--flat .irs-bar {
  height: 2px;
  top: 30px;
  cursor: pointer;
}
.delivery-slider .irs--flat .irs-handle {
  width: 0;
}
.delivery-slider .irs--flat .irs-handle > i:first-child {
  top: 50%;
  width: 15px;
  height: 15px;
  background-color: #ba1c24;
  border-radius: 50%;
  transform: translate(0px, -50%);
  cursor: pointer;
}
.delivery-slider .irs--flat .irs-bar {
  background-color: #ba1c24;
}
.delivery-slider .irs--flat .irs-from:before,
.delivery-slider .irs--flat .irs-to:before,
.irs--flat .irs-single:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #ba1c24;
}
.delivery-slider .irs--flat .irs-from,
.delivery-slider .irs--flat .irs-to,
.delivery-slider .irs--flat .irs-single {
  color: white;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background-color: #ba1c24;
  border-radius: 4px;
}
/* Subscribe */

#subscribe-form {
  float: none;
  margin: 0 25%;
}
.block_newsletter form {
  position: relative;
}
.subscribe-content {
  background-color: #ffffff;
  height: 70px;
  width: 100%;
  position: relative;
  border-radius: 999px;
}
#subscribe-input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: 0 none;
  float: left;
  height: 100%;
  padding: 0 0 0 30px;
  width: 100%;
  border: 1px #ba1c24 solid;
  border-right: none;
  box-shadow: none;
  border-radius: 999px;
  font-size: 16px;
  margin: 0px;
}
.block_newsletter form input[type="text"] {
  border: none;
  padding: 10px;
  min-width: 255px;
}
.block_newsletter form input[type="text"] {
  padding: 12px;
  width: 100%;
}
.block_newsletter form input {
  height: 50px;
  padding: 12px 30px;
  border: 1px solid #fff;
  outline: 0;
  font-weight: 400;
}
.subscribe-content button.button {
  overflow: visible;
  width: auto;
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}
.subscribe-content button.button span {
  background: #ba1c24;
  border-left: 1px solid #ba1c24;
  color: #fff;
  font-size: 18px;
  height: 70px;
  line-height: 70px;
  text-transform: uppercase;
  transition: all 0.3s ease-in;
  display: inline-block;
  padding: 0px 25px;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-family: "Open Sans", sans-serif;
  border-radius: 0px 999px 999px 0px;
}
.subscribe-content button.button:hover span {
  background-color: #000000;
  color: #ffffff;
}
/* 404 */
.page-404 {
  background-color: #141433;
  background-image: url("../img/404.jpg");
  background-position: center;
  position: relative;
}
.page-404:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #141433;
  opacity: 0.9;
}
#page-404 section {
  display: flex;
  justify-content: center;
  height: 100vh;
  text-align: center;
  align-items: center;
  width: 100%;
}
#page-404 .caption h5 {
  margin: 50px 0;
  font-weight: 400;
}
.coming-soon .content-wrapper h1 a,
#page-404 .caption h5 a {
  text-decoration: underline;
  text-underline-position: under;
  color: #fff;
}
/* Compare */

.compare-table .table tbody tr td.first-column {
  min-width: 175px;
  font-size: 15px;
  font-weight: 500;
  color: #222222;
  margin: 0;
}
.compare-table .table tbody tr td {
  text-align: center;
  padding: 20px 20px;
  vertical-align: middle;
  border-color: #ccc;
}
.compare-table .table tbody tr td.product-image-title {
  min-width: 310px;
  vertical-align: bottom;
}
.compare-table .table tbody tr td {
  text-align: center;
  padding: 20px 20px;
  vertical-align: middle;
  border-color: #ccc;
}
.compare-table .table tbody tr td.product-image-title .image {
  clear: both;
  width: 100%;
  margin-bottom: 15px;
  display: block;
}
.compare-table .table tbody tr td.product-image-title .category {
  float: left;
  clear: both;
  color: #141433;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}
.compare-table .table tbody tr td.product-image-title .title {
  float: left;
  clear: both;
  font-size: 16px;
  color: #222222;
  font-weight: 500;
  text-transform: capitalize;
}
.compare-table .table tbody tr td.pro-price,
.compare-table .table tbody tr td.pro-color,
.compare-table .table tbody tr td.pro-stock {
  font-size: 14px;
  font-weight: 400;
}
.compare-table .table tbody tr td.pro-ratting i {
  color: #141433;
}
.compare-table .table tbody tr td.pro-remove button {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}
.pro-remove button i,
.pro-remove a i {
  font-size: 26px;
}
.pro-remove a:hover i,
.pro-remove button:hover i {
  color: red;
}
/* Shop-Tabs */

.shop-tabs.nav-pills .nav-link {
  color: #222222;
  background-color: transparent;
  font-weight: 600;
}
.shop-tabs.nav-pills .nav-link.active,
.shop-tabs.nav-pills .show > .nav-link {
  color: #ba1c24;
  background-color: transparent;
  border-bottom: 1px solid #ba1c24;
  border-radius: 0px;
  font-weight: 600;
}
.blog-link-wrap .btn-text {
  font-size: 14px;
  text-transform: capitalize;
  color: #141433;
}
.blog-link-wrap .btn-text::before {
  background-color: #141433;
}
/* Wishlist */

.cart-table .table {
  margin: 0;
  white-space: nowrap;
}
.cart-table .table thead {
  background-color: #141433;
}
.cart-table .table tr .pro-thumbnail,
.cart-table .table tr .pro-price,
.cart-table .table tr .pro-quantity,
.cart-table .table tr .pro-subtotal,
.cart-table .table tr .pro-remove {
  width: 140px;
}
.cart-table .table thead tr th {
  border-color: #141433;
  border-bottom: 0 solid transparent;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  padding: 15px 20px;
  text-align: center;
  text-transform: capitalize;
}
.cart-table .table tbody tr td {
  border-color: #ccc;
  text-align: center;
  padding: 10px 20px;
  vertical-align: middle;
  font-weight: 500;
  color: #222222;
}
.cart-table .table tbody tr td a {
  color: #222222;
  font-weight: 400;
  text-transform: capitalize;
}
.cart-table .table tbody tr td a.btn {
  color: #ffffff;
}
.product-img a img {
  overflow: hidden;
}
/* Services box */

.service-box {
  padding: 80px 0;
}
.service-box-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  margin: 0 auto;
}
.service-box-wrapper .service-icon-box {
  width: 80px;
  height: 80px;
  border: 1px solid #fff;
  border-radius: 50%;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #667a62;
  font-size: 35px;
  transition: 0.5s all;
}
.service-box-wrapper .service-icon-box img {
  width: 40px;
  height: 40px;
}
.service-box:hover .service-box-wrapper .service-icon-box {
  transform: translateY(-10px);
}
.service-text-box h6 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
}
.service-text-box p,
.service-text-box h6 {
  color: #ffffff;
}
.border-custom-right {
  border-right: 1px solid #141433;
}
.bg-custom-primary {
  background-color: #014713;
}
.bg-custom-secondary {
  background-color: #141433;
}
.text-color-primary {
  color: #ba1c24;
}
.text-color-secondary {
  color: #141433;
}
/* Shop-cards */

.product-box .product-raised strong {
  color: #464545;
  font-weight: 600;
}
.product-box .product-raised li {
  font-size: 14px;
  color: #ba1c24;
  font-weight: 700;
  margin: 0 2px;
}
.product-box .progress {
  height: 8px;
  background-color: #141433;
}
.product-box .product-raised {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.product-box .progress-bar-color {
  background-color: #ba1c24;
}
.product-box .progress .progress-bar {
  animation-name: animateBar;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.4s;
}
.product-box .title span:hover a {
  color: #1d1b19;
}
@keyframes animateBar {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
/* Advertisement-Banner 1 */

.promo-se-icons .icon-box {
  max-width: 190px;
  position: relative;
}
.promo-se-icons .icon-box img {
  margin-right: 17px;
}
.promo-se-icons .icon-box h5 {
  font-size: 21px;
  font-weight: 400;
  max-width: 110px;
  color: #ffffff;
  margin-bottom: 0;
}
.promo-se-icons .icon-box:after {
  position: absolute;
  content: close-quote;
  height: 50px;
  width: 2px;
  background-color: #fff;
  right: -41px;
  opacity: 0.3;
}
.promo-se-icons .icon-box.last:after {
  opacity: 0;
}
.slab {
  font-family: "Open Sans", sans-serif;
}
.align-custom-right {
  margin: 0 0 0 auto;
}
/* Video-icon */

@keyframes pulse-blue {
  0% {
    box-shadow: 0 0 0 0 rgb(186, 28, 36);
  }
  70% {
    box-shadow: 0 0 0 30px rgb(209, 162, 132, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgb(209, 162, 132, 0);
  }
}
@keyframes pulse-blue-small {
  0% {
    box-shadow: 0 0 0 0 rgba(41, 137, 216, 0.7);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(41, 137, 216, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(41, 137, 216, 0);
  }
}
.play-btn {
  width: 75px;
  height: 75px;
  text-align: center;
  border-radius: 50%;
}
.blob {
  box-shadow: 0 0 0 0 rgb(76, 39, 60);
  animation: pulse-blue 2s infinite;
  background: #ba1c24;
}
.play-btn {
  margin: auto;
  margin-bottom: 20px;
}
.play-btn i {
  color: #fff;
  line-height: 75px;
}
/* Storybox */

.story-wrapper {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  border-radius: 4px;
}
.story-wrapper .story-box-content {
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  text-align: center;
  padding: 30px 0;
}
.story-wrapper .story-content-wrapper {
  left: 0px;
  right: 0px;
  top: 50%;
  position: absolute;
  pointer-events: none;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2;
}
.story-wrapper .story-content-wrapper h5 {
  margin-bottom: 10px;
}
.story-wrapper::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.54);
  top: 0;
  left: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0.8;
  cursor: pointer;
  z-index: 1;
}
.story-wrapper .story-box-content .story-badge {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  padding: 6px 13px;
  margin-bottom: 22px;
  border-radius: 4px;
  color: #fff;
}
.story-wrapper .story-box-content h5 a {
  color: #ffffff;
}
.blog-item:hover .blog-item-img img,
.story-wrapper:hover img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
.blog-item .blog-item-img img,
.story-wrapper img {
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  -o-transition: transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}
.story-wrapper:hover:after {
  background-color: rgba(76, 40, 60, 0.69);
  bottom: 0;
  top: 0;
}
.story-wrapper:hover:before {
  background-color: rgba(186, 28, 36, 0.47);
  left: 0;
  right: 0;
}
.story-wrapper:before {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  z-index: 1;
}
.story-wrapper:after {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  z-index: 1;
}
.high {
  background-color: #2ec774;
}
.Ceramicart {
  background-color: #9a4bff;
}
.uni {
  background-color: #f36f8f;
}
.Handmade {
  background-color: #49c2df;
}
.kids {
  background-color: #e19c41;
}
/* ads-Banner-2 */

.center-block-div {
  margin: 0 auto;
  text-align: center;
}
/* Donation-program */

.donation-pro-content {
  left: 0px;
  right: 0px;
  top: 50%;
  position: absolute;
  pointer-events: none;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2;
}
.dontaion-pro-box {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
.dontaion-pro-box .cause-progress {
  margin: 70px 0 20px 0;
  border-radius: 6px;
  background: #141433;
}
.dontaion-pro-box .cause-progress .progress-bar {
  background: #ba1c24;
  height: 7px;
  position: relative;
  border-radius: 6px;
}
.dontaion-pro-box .cause-progress .progress-bar span {
  position: absolute;
  top: -43px;
  right: 0;
  background: #ba1c24;
  padding: 0 5px;
  font-size: 11px;
  height: 22px;
  line-height: 24px;
  border-radius: 2px;
}
.dontaion-pro-box .cause-progress .progress-bar span:after {
  content: "";
  position: absolute;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #ba1c24;
  bottom: -6px;
  left: 50%;
  margin-left: -6px;
}
.dontaion-pro-box .causes-amount {
  text-align: center;
  overflow: hidden;
  border: 0;
  margin-bottom: 0px;
}
.dontaion-pro-box .causes-amount .left,
.dontaion-pro-box .causes-amount .right {
  width: 50%;
  display: block;
  float: left;
}
.dontaion-pro-box .causes-amount .left {
  text-align: left;
}
.dontaion-pro-box .causes-amount .right {
  text-align: right;
}
.dontaion-pro-box .causes-amount p {
  margin-bottom: 0;
  color: #212121;
  font-weight: 600;
}
.dontaion-pro-box .causes-amount span {
  font-weight: 600;
  font-size: 18px;
  color: #ba1c24;
}
.banner-statistics.dontaion-promo:hover img {
  transform: scale(1.1);
  opacity: 0.4;
}
/* Blog */
.our-articles .main-box.padding-20.full-width {
  padding: 30px;
}
.our-articles .post-wrapper .profile-img img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.blog-item-box {
  padding: 20px;
  background-color: #fff;
  -webkit-box-shadow: 0 3px 15px 2px rgba(0, 0, 0, 0.06);
  box-shadow: 0 3px 15px 2px rgba(0, 0, 0, 0.06);
}
.blog-item {
  margin-bottom: 30px;
}
.our-articles .post-wrapper img,
.blog-item-img {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}
.blog-item-img::before {
  content: "";
  display: block;
  padding-top: 76.4706%;
}
.our-articles .post-wrapper .blog-item-img::before {
  padding-top: 0;
}
.blog-item-img img {
  z-index: 1;
}
.blog-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top center;
  object-position: top center;
  z-index: -1;
}
.our-articles .post-wrapper .blog-item-badge,
.blog-item-box .blog-item-badge {
  position: absolute;
  bottom: 0;
  left: -1px;
  z-index: 1;
  color: #fff;
  display: inline-flex;
  font-size: 14px;
  font-weight: 400;
  padding: 6px 13px;
  border-radius: 0px 5px 0px 5px;
}
.our-articles .post-wrapper .blog-item-badge li a,
.blog-item-box .blog-item-badge li a {
  color: #ffffff;
  font-size: 14px;
}
.our-articles .post-wrapper .blog-item-badge li:after,
.blog-item-box .blog-item-badge > li:after {
  background-color: #ffffff;
  content: "";
  height: 1px;
  right: 0;
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  left: 0;
}
.our-articles .post-wrapper .blog-item-badge li:last-child:after,
.blog-item-box .blog-item-badge > li:last-child:after {
  content: none;
}
.blog-item-box .blog-item-content {
  padding: 15px 0px 0px 0px;
}
.blog-item-box .blog-item-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
}
.blog-item-box .blog-item-title a {
  text-decoration: none;
  transition: opacity 0.3s;
  color: #2c2c48;
}
.blog-item-box .blog-item-title:hover a {
  color: #ba1c24;
}
.blog-item-badge {
  background-color: #ba1c24;
}
.blog-item-box .blog-item-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 14px;
  margin-top: 15px;
  align-items: center;
  align-self: center;
  color: #464545;
  font-weight: 600;
}
.blog-item-details span i {
  color: #ba1c24;
  margin-right: 5px;
  vertical-align: middle;
}
/* Donation-card */

.sa-causes-single-2 {
  text-align: left;
}
.sa-causes-single {
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
  margin-bottom: 30px;
  border-radius: 4px;
  transition: 0.3s;
}
.sa-causes-single .entry-thumb.mtmb-spacing {
  margin-bottom: 25px;
}
.sa-causes-single .entry-thumb,
.event_img {
  position: relative;
  overflow: hidden;
}
.sa-causes-single .entry-thumb + .causes-details-wrap .cause-progress {
  margin-top: 20px;
}
.sa-causes-single .entry-thumb img {
  border-radius: 4px 4px 0 0;
  margin-left: 30px;
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.sa-causes-single:hover img {
  margin-left: 0;
}
.sa-causes-single .entry-thumb:after {
  content: "";
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.10196) 50%,
    rgba(0, 0, 0, 0.05) 58%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: -o-linear-gradient(
    left,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.10196) 50%,
    rgba(0, 0, 0, 0.05) 58%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.10196) 60%,
    rgba(0, 0, 0, 0.05) 68%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.sa-causes-single .causes-details-wrap {
  padding: 15px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.sa-causes-single .causes-details {
  text-align: left;
}
.sa-causes-single .causes-details-wrap .causes-details h5 {
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 600;
}
.sa-causes-single-2 .causes-details h3 {
  margin-top: 0;
}
.sa-causes-single .causes-details h3 a {
  font-size: 22px;
  color: #ba1c24;
  font-weight: 600;
}
.our-articles .causes-details .cause-progress,
.sa-causes-single .causes-details .cause-progress {
  margin: 0px 0 20px 0;
  border-radius: 6px;
}
.our-articles .cause-progress,
.sa-causes-single .cause-progress {
  background: #141433;
}
.our-articles .cause-progress .progress-bar,
.sa-causes-single-2 .cause-progress .progress-bar {
  border-radius: 6px;
  overflow: visible;
}
.our-articles .cause-progress .progress-bar,
.sa-causes-single .cause-progress .progress-bar {
  background: #ba1c24;
  height: 7px;
  position: relative;
}
.our-articles .cause-progress .progress-bar span,
.sa-causes-single .cause-progress .progress-bar span {
  position: absolute;
  top: -43px;
  right: 0;
  background: #141433;
  padding: 0 5px;
  font-size: 11px;
  height: 22px;
  line-height: 24px;
  border-radius: 2px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all;
  border-bottom-right-radius: 0;
  z-index: 10;
}
.sa-causes-single:hover .cause-progress .progress-bar span {
  opacity: 1;
  visibility: visible;
}
.our-articles .cause-progress .progress-bar span:after,
.sa-causes-single .cause-progress .progress-bar span:after {
  content: "";
  position: absolute;
  bottom: -6px;
  left: auto;
  margin-left: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 6px solid #141433;
  border-left: 12px solid transparent;
}
.our-articles .causes-amount,
.sa-causes-single .causes-amount {
  margin-bottom: 0px;
}
.our-articles .causes-amount .left,
.our-articles .causes-amount .sa-causes-single-2 .causes-amount .left,
.sa-causes-single-2 .causes-amount {
  border: 0;
}
.our-articles .cause-progress {
  margin-top: 60px;
}
.our-articles .cause-progress,
.our-articles .causes-amount {
  margin-bottom: 20px;
}
.our-articles .causes-amount,
.sa-causes-single .causes-amount {
  text-align: center;
  overflow: hidden;
  margin-bottom: 0px;
}
.our-articles .causes-amount .left,
.sa-causes-single-2 .causes-amount .left {
  text-align: left;
}
.our-articles .causes-amount .right,
.our-articles .causes-amount .left,
.sa-causes-single .causes-amount .right,
.sa-causes-single .causes-amount .left {
  width: 50%;
  display: block;
  float: left;
}
.our-articles .causes-amount p,
.sa-causes-single-2 .causes-amount p {
  color: #464545;
  font-weight: 600;
}
.our-articles .causes-amount p,
.sa-causes-single .causes-details p {
  margin-bottom: 0;
}
.our-articles .causes-amount span,
.sa-causes-single-2 .causes-amount span {
  color: #022147;
}
.our-articles .causes-amount span,
.sa-causes-single .causes-amount span {
  font-weight: 700;
  font-size: 16px;
  color: #ba1c24;
}
.our-articles .causes-amount .right,
.sa-causes-single-2 .causes-amount .right {
  text-align: right;
}
.sa-causes-single .dontaion-category {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
  display: inline-block;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 27px;
  padding: 5px 15px;
  background-color: #ba1c24;
  border-radius: 0px 4px 0px 4px;
}
.dontaion-category a {
  color: #fff;
}
.btn-area {
  background: #ba1c24;
  padding: 15px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.5s;
}
.btn-donation {
  font-weight: 600;
  letter-spacing: 1.5px;
  color: #fff;
}
.btn-area:hover .btn-donation {
  color: #fff;
}
.btn-area:hover {
  background-color: #141433;
}
.btn-area:hover a {
  color: #ba1c24;
}
.mx-center-width {
  max-width: 550px;
  margin: 0 auto;
}
.about-section-container {
  padding: 80px 60px;
}
.about-section-container .section-header-left .header-title {
  margin-bottom: 20px;
}
.about-section-container .section-header-left .btn {
  margin-top: 5px;
}
.heading-title {
  display: inline-block;
  color: #141433;
  font-weight: 100;
  margin-bottom: 20px;
  font-size: 18px;
}
.section-header-left span a {
  color: #fff;
  letter-spacing: 0.5px;
  font-weight: 500;
  text-align: center;
  line-height: 1.75rem;
  position: relative;
  white-space: normal;
  z-index: 1;
  background: #ba1c24;
  transition: all 0.5s ease;
  border-radius: 4px;
  padding: 5px 16px;
  font-size: 14px;
  display: inline-block;
}
/* large-product-box */

.large-product-box .featured-product-box {
  padding: 15px;
  position: relative;
  border-radius: 4px;
  background: #fff;
}
.box-shadow {
  border: 1px solid #eae8f5;
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
}
.large-product-box .featured-product-box .featured-pro-title {
  border-bottom: 1px solid #eae8f5;
  margin-bottom: 15px;
}
.large-product-box .featured-product-box h5,
.large-product-box .featured-product-box .featured-pro-title h4 {
  margin-bottom: 15px;
}
.featured-pro-content .featured-pro-text h6 a {
  font-weight: 500;
  color: #141433;
}
.featured-pro-content .featured-pro-text p {
  color: gray;
}
.featured-pro-content .featured-pro-text .price {
  font-size: 26px;
  font-weight: 600;
  color: #ba1c24;
  margin-bottom: 15px;
  line-height: normal;
}
.countdown-box .time-box:last-child {
  margin-right: 0;
}
.large-product-box .featured-pro-bottom {
  padding-top: 15px;
  border-top: 1px solid #eee;
}
.large-product-box .featured-pro-bottom ul {
  display: flex;
  justify-content: space-between;
}
.large-product-box .featured-pro-bottom ul li {
  font-size: 14px;
  color: #464545;
  font-weight: 600;
}
.large-product-box .featured-pro-bottom ul li strong {
  font-size: 16px;
  color: #ba1c24;
  font-weight: 700;
}
.featured-pro-timer .countdown-box {
  text-align: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
.featured-pro-timer .countdown-box .counter-box {
  background: white;
  padding: 5px 9px;
  border: 1px solid #141433;
  border-radius: 4px;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  align-items: center;
  justify-content: center;
  background: #141433;
  width: 50px;
  height: 65px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 600;
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
  margin: 0 auto;
}
/*Banner */

.slider-banner-1 .swiper-button-prev {
  background: #ba1c24;
  width: 60px;
  height: 60px;
}
.slider-banner-1 .swiper-button-next {
  background: #ba1c24;
  width: 60px;
  height: 60px;
}
.feedback-slider .swiper-button-prev,
.feedback-slider .swiper-button-next {
  background-color: #141433;
  color: #ba1c24;
}
.feedback-slider .swiper-button-next:after,
.feedback-slider .swiper-button-prev:after {
  color: #ffffff;
}

.dontaion-category.story-meta {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 400;
}

.dontaion-category.story-meta p {
  margin-bottom: 0;
}

.our-articles .cause-progress.profile-donate {
  margin-top: 0px;
}

.post-details-tags-social {
  padding-top: 5px;
}
.post-details-tags-social .tags-box {
  display: flex;
  align-items: flex-start;
}
.post-details-tags-social .tags {
  margin-left: 15px;
}
.post-details-tags-social .tags a {
  display: inline-block;
  padding: 5px 15px;
  border: #ba1c24 solid 1px;
  border-radius: 4px;
  margin: 0 5px 10px 0;
  background: #ffffff;
  font-weight: 500;
  color: #ba1c24;
}
.post-details-tags-social .tags a:hover {
  border-color: #ba1c24;
  color: #ffffff;
  background: #ba1c24;
}
.social-media-box > ul {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
}
.social-media-box > ul > li {
  padding-left: 0;
  margin: 0;
  margin-left: 10px;
}
.social-media-box > ul > li > a.fb {
  color: #4661c5;
  border: 1px solid #4661c5;
}
.social-media-box > ul > li > a.tw {
  color: #45a4e6;
  border: 1px solid #45a4e6;
}
.social-media-box > ul > li > a.gg {
  color: #d6463a;
  border: 1px solid #d6463a;
}
.social-media-box > ul > li > a.ln {
  color: #007bb5;
  border: 1px solid #007bb5;
}
.social-media-box > ul > li > a {
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: none;
  box-shadow: 4px 3px 10px 0px rgba(0, 0, 0, 0.08);
  padding: 10px 14px;
}
.social-media-box > ul > li > a.fb:hover {
  color: #fff;
  background: #4661c5;
}
.social-media-box > ul > li > a.tw:hover {
  color: #fff;
  background: #45a4e6;
}
.social-media-box > ul > li > a.gg:hover {
  color: #fff;
  background: #d6463a;
}
.social-media-box > ul > li > a.ln:hover {
  color: #fff;
  background: #007bb5;
}
.bottom-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bottom-group .social-media-box > ul {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0;
}
.user-gallery a img {
  margin-bottom: 30px;
}
.entry-title,
.product-title {
  font-size: 18px;
}
.entry-title {
  margin-bottom: 5px;
}
.entry-tye span.text-light-white {
  font-size: 13px;
  color: #ba1c24;
  font-weight: 600;
}
.text-404 {
  font-size: 250px;
  color: #ba1c24;
  font-weight: bolder;
}
.paravideo-icon-sec {
  background-color: #ba1c24;
  height: 535px;
}
.paravideo-icon-sec .icon-sec .icon-box {
  margin: 0 auto;
  max-width: 150px;
  text-align: center;
  font-size: 70px;
  line-height: 1;
  transition: 0.5s all;
}
.paravideo-icon-sec .icon-sec .icon-box img {
  width: 55px;
}
.paravideo-icon-sec .icon-sec .icon-box a {
  color: #fff;
}
.paravideo-icon-sec .icon-sec .icon-box:hover {
  transform: translateY(-10px);
}
.paravideo-sec {
  margin-top: -300px;
  position: relative;
  z-index: 1;
}
.paravideo-sec .video-sec .video-sec-left {
  background-color: #141433;
  display: flex;
  align-items: center;
  justify-content: center;
}
.paravideo-sec .video-sec .video-sec-left .video-sec-left-inner ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.paravideo-sec .video-sec .video-sec-left .video-sec-left-inner h3 {
  margin-bottom: 30px;
}
.paravideo-sec .video-sec .video-sec-left .video-sec-left-inner ul li:after {
  content: "|";
  color: #fff;
  margin: 0 15px;
}
.paravideo-sec
  .video-sec
  .video-sec-left
  .video-sec-left-inner
  ul
  li:last-child:after {
  display: none;
}
.paravideo-sec .video-sec .video-sec-left .video-sec-left-inner ul li i {
  margin-right: 5px;
}
.paravideo-sec
  .video-sec
  .video-sec-left
  .video-sec-left-inner
  .upcoming-cam-timer
  .countdown-box {
  text-align: center;
  justify-content: center;
  margin-top: 20px;
}
.paravideo-sec
  .video-sec
  .video-sec-left
  .video-sec-left-inner
  .upcoming-cam-timer
  .countdown-box
  .counter-box {
  background: white;
  padding: 8px 9px;
  border: 1px solid #ba1c24;
  border-radius: 4px;
  text-align: center;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  color: #fff;
  align-items: center;
  justify-content: center;
  background: #ba1c24;
  width: 70px;
  height: 70px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 600;
  box-shadow: 0 0 0 1px rgba(67, 41, 163, 0.08),
    0 1px 5px 0 rgba(67, 41, 163, 0.08);
}
.paravideo-sec
  .video-sec
  .video-sec-left
  .video-sec-left-inner
  .upcoming-cam-timer
  .countdown-box
  .counter-box
  span {
  font-size: 26px;
  line-height: 1;
}
.paravideo-sec
  .video-sec
  .video-sec-left
  .video-sec-left-inner
  .upcoming-cam-timer
  .countdown-box
  .counter-box
  span
  p {
  font-size: 14px;
}
.paravideo-sec
  .video-sec
  .video-sec-left
  .video-sec-left-inner
  .upcoming-cam-timer
  .countdown-box
  .counter-box:first-child {
  margin-left: 0px;
}
.paravideo-sec
  .video-sec
  .video-sec-left
  .video-sec-left-inner
  .upcoming-cam-timer
  .countdown-box
  .counter-box:last-child {
  margin-right: 0px;
}
.paravideo-sec .video-sec .video-sec-right .video_wrapper {
  min-height: 400px;
  height: 600px;
}
.paravideo-sec .video-sec .video-sec-right .video_wrapper img {
  border-radius: 0;
}
.paravideo-sec
  .video-sec
  .video-sec-right
  .video_wrapper
  .videoPoster
  .video-inner
  i {
  font-size: 45px;
  margin-left: 5px;
}

.verticle-newsletter .verticle-newsletter-inner {
  padding: 20px;
}
.verticle-newsletter .verticle-newsletter-inner .register-info-box,
.verticle-newsletter .verticle-newsletter-inner .group-form {
  border-bottom: 1px solid #eae8f5;
  padding-bottom: 20px;
}
.verticle-newsletter .verticle-newsletter-inner .register-info-box .icon-sec {
  margin: 0 auto 20px;
  font-size: 70px;
  text-align: center;
  line-height: 1;
  color: #ba1c24;
}
.group-form input {
  border-right: 0;
}
.group-form button {
  background: #ba1c24;
  color: #fff;
  padding: 0 20px;
  border-radius: 0 4px 4px 0;
  transition: 0.5s all;
}
.group-form button:hover {
  background-color: #141433;
}
.horizontal-line {
  position: relative;
}
.horizontal-line:before {
  content: "";
  background: -webkit-linear-gradient(
    left,
    #141433,
    #233359 50%,
    #2e3f6a 50%,
    #ba1c24
  );
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
}
/* About section */
.aboutus .history-title {
  overflow: hidden;
}
.aboutus .history-title .about-box ul {
  margin-bottom: 15px;
}
.aboutus .history-title .about-box ul li {
  margin-bottom: 5px;
  font-weight: 600;
}
.aboutus .history-title .about-box ul li i {
  margin-right: 10px;
  color: #ba1c24;
}
.aboutus .history-title .img-box,
.aboutus .history-title .about-box {
  padding-right: 18%;
  overflow: hidden;
}
.aboutus .history-title .img-box {
  height: calc(100% - 145px);
}
.aboutus .history-title h2 {
  max-width: 850px;
}
.custom-lh {
  line-height: 24px;
}
.aboutus .about-quote-box {
  position: relative;
  padding: 27px 20px;
  background-color: #141433;
  margin-bottom: 20px;
}
.aboutus .about-quote-box i {
  text-align: right;
  width: 100%;
  font-size: 40px;
  color: #fff;
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.masonary-gallery-sec .row {
  margin-left: -5px;
  margin-right: -5px;
}
.masonary-gallery-sec .row > div {
  padding: 5px;
}
.masonary-gallery-sec .masonary-box {
  background: #ba1c24;
  min-height: 400px;
}
.masonary-gallery-sec .masonary-text .masonary-text-wrapper {
  display: flex;
  align-items: center;
  max-width: 750px;
  margin: 0 auto;
}
.masonary-gallery-sec .masonary-text .masonary-text-wrapper .icon-box {
  flex: 0 0 240px;
  max-width: 240px;
  height: 240px;
}
.masonary-gallery-sec .masonary-text .masonary-text-wrapper .text-box {
  flex: 0 0 calc(100% - 240px);
  max-width: calc(100% - 240px);
  padding-left: 25px;
}
.masonary-gallery-sec .masonary-text .masonary-text-wrapper .text-box a {
  color: #fff;
}
.masonary-gallery-sec .masonary-item {
  height: 100%;
}
.masonary-gallery-sec .video-box .video_wrapper .videoPoster img,
.masonary-gallery-sec .masonary-item img {
  border-radius: 0;
}
.masonary-gallery-sec .masonary-item .popup:before {
  position: absolute;
  top: 50%;
  opacity: 0;
  color: #fff;
  font-size: 26px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f002";
  pointer-events: none;
  z-index: 9000;
  transition: 0.4s;
  transform: translate(0px, -50%);
  left: 0;
  right: 0;
  text-align: center;
}
.masonary-gallery-sec .masonary-item .popup:after {
  position: absolute;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: #ba1c24;
  content: "";
  transition: 0.4s;
}
.masonary-gallery-sec .masonary-item:hover .popup:before,
.masonary-gallery-sec .masonary-item:hover .popup:after {
  opacity: 0.5;
}
.masonary-gallery-sec .video-box .video_wrapper {
  height: 400px;
}
.masonary-gallery-sec .video-box .video_wrapper .videoPoster {
  position: relative;
}
.masonary-gallery-sec .video-box iframe {
  height: 100%;
}
.masonary-gallery-sec .video-box .video_wrapper .videoPoster .video-inner a {
  border: 2px solid #ba1c24;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 74px;
  height: 74px;
  position: relative;
}
.masonary-gallery-sec .video-box .video_wrapper .videoPoster .video-inner a i {
  color: #ba1c24;
  position: absolute;
  left: 5px;
}
.copyright .medewithlove a {
  color: #fff;
}
.bg-banner-mission {
  background-image: url(../img/innerbanner2.jpg);
}
.bg-banner-sponsr {
  background-image: url(../img/innerbanner2.jpg);
}
.single-banner {
  height: 800px;
}
.single-banner > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.single-banner p {
  font-size: 16px;
}
.fs-60 {
  font-size: 60px;
}
.overlay-bg-dark-2 {
  background: #000000;
  opacity: 0.5;
}
.causes-details h5 a {
  color: #141433;
}

.causes-details h5:hover a {
  color: #ba1c24;
}
.rangeslider__labels .rangeslider__label-item {
  display: inline-block !important;
  position: absolute !important;
  font-size: 12px;
  white-space: nowrap;
}
.rangeslider-horizontal {
  border-radius: 0;
  box-shadow: none;
  height: 3px;
}
.rangeslider-horizontal .rangeslider__fill {
  border-radius: 0;
  background-color: #ba1c24;
  box-shadow: none;
}
.rangeslider-horizontal .rangeslider__handle:after {
  display: none;
}
.rangeslider-horizontal .rangeslider__handle {
  width: 15px;
  height: 15px;
  box-shadow: none;
  border-color: #ba1c24;
  background-color: #ba1c24;
}
.alert.d-block {
  display: block !important;
}
/* About */
